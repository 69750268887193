import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateMaintenance } from '../../../hooks/api/maintenanceService';
import customToastComponent from '../../../utils/customToast';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const createMaintenanceSlice = createSlice({
  name: 'createMaintenanceSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createMaintenanceAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createMaintenanceAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(createMaintenanceAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const createMaintenanceAction = createAsyncThunk('createMaintenanceAction', async (data, thunkApi) => {
  return CreateMaintenance(data)
    .then((res) => {
      customToastComponent(res?.data?.message ?? 'Created Successfully');
      return res;
    })
    .catch((err) => {
      customToastComponent(err?.message ?? 'Error Creating Workorder', true);
      thunkApi.rejectWithValue(err.message);
    });
});

export default createMaintenanceSlice.reducer;
