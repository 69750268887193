import api from '../../utils/api';
import { useMutation } from 'react-query';
import { ApiEndpoints } from '../../config/Endpoints';
import moment from 'moment';
const currentYear = new Date().toISOString();
const today = new Date();
//using tomorrow's date, so we don't get back stale data on query invalidation
let tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);
tomorrow = new Date(tomorrow).toISOString();

export async function CreateCommunity(payload) {
  const response = await api.post(`${ApiEndpoints.CREATE_COMMUNITY}`, payload);

  return response;
}

export async function GetAllCommunities(payload) {
  if (payload?.fromDate) {
    const response = await api.get(
      `${ApiEndpoints.ALL_COMMUNITY}?FromDate=${payload?.fromDate}&ToDate=${
        payload?.toDate ?? moment().toISOString()
      }&CommunityType=${payload?.communityType}&pageNumber=${payload?.pageIndex ?? 1}&pageSize=${
        payload?.pageSize ?? 10
      }`,
    );

    return response;
  } else {
    const response = await api.get(
      `${ApiEndpoints.ALL_COMMUNITY}?ToDate=${payload?.toDate ?? moment().toISOString()}&CommunityType=${
        payload?.communityType
      }&pageNumber=${payload?.pageIndex ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
    );

    return response;
  }
}
export async function GetStaffCommunities(payload) {
  if (payload?.fromDate) {
    const response = await api.get(
      `${ApiEndpoints.STAFF_COMMUNITY}?FromDate=${payload?.fromDate}&ToDate=${
        payload?.toDate ?? currentYear
      }&CommunityType=${payload?.communityType}&pageNumber=${payload?.pageIndex ?? 1}&pageSize=${
        payload?.pageSize ?? 10
      }`,
    );

    return response;
  } else {
    const response = await api.get(
      `${ApiEndpoints.STAFF_COMMUNITY}?ToDate=${payload?.toDate ?? currentYear}&CommunityType=${
        payload?.communityType
      }&pageNumber=${payload?.pageIndex ?? 1}&pageSize=${payload?.pageSize ?? 10}`,
    );

    return response;
  }
}

export async function GetAllPartnerCommunities(payload) {
  const response = await api.get(
    `${ApiEndpoints.VIEW_PARTNER_COMMUNITY}?ToDate=${currentYear}&Name=${payload?.name || ''}&pageNumber=${
      payload?.pageIndex
    }&pageSize=${payload?.pageSize}`,
  );
  return response;
}

async function FilterAllCommunities(payload) {
  const response = await api.get(
    `${ApiEndpoints.ALL_COLLECTION}?FromDate=${payload?.startDate}&ToDate=${payload?.toDate}&pageNumber=${payload?.pageIndex}&pageSize=${payload?.pageSize}`,
  );
  return response;
}

async function AddCommunityMembers(payload) {
  const response = await api.post(`${ApiEndpoints.ADD_COMMUNITY_MEMBERS}`, payload);

  return response;
}
export async function AssignStaffToCommunity(payload) {
  const response = await api.post(`${ApiEndpoints.ASSIGN_STAFF}`, payload);

  return response;
}
export async function ViewCommunityMembers(id) {
  const response = await api.get(`${ApiEndpoints.VIEW_COMMUNITY_MEMBERS}/${id}`);

  return response;
}

export async function ViewCommunity(id) {
  const response = await api.get(`${ApiEndpoints.VIEW_COMMUNITY}/${id}`);

  return response;
}
export async function UpdateCommunity({ id, payload }) {
  const response = await api.patch(`${ApiEndpoints.UPDATE_COMMUNITY}/${id}`, payload);

  return response;
}
export async function CommunitySummaryService() {
  const response = await api.get(`${ApiEndpoints.COMMUNITY_SUMMARY}`);

  return response;
}

export async function viewDevices(payload) {
  const response = await api.get(`${ApiEndpoints.VIEW_DEVICES}/${payload?.id}`);
  return response;
}

export async function PropertySummaryService(id) {
  const response = await api.get(`${ApiEndpoints.PROPERTY_SUMMARY}/${id}`);

  return response;
}
export async function ResidentSummaryService(id) {
  const response = await api.get(`${ApiEndpoints.RESIDENT_SUMMARY}/${id}`);

  return response;
}

export async function GetCommuntyStaffService(id) {
  const response = await api.get(`${ApiEndpoints.COMMUNITY_STAFFS}/${id}`);

  return response;
}
export async function deleteCommuntyStaffService(id) {
  const response = await api.patch(`${ApiEndpoints.REMOVE_COMMUNITY_STAFFS}/${id}`);

  return response;
}
export async function updateCommuntyStaffManagerService(id) {
  const response = await api.patch(`${ApiEndpoints.UPDATE_COMMUNITY_STAFFS_MANAGER}/${id}`);

  return response;
}

export async function CommunityDropdown() {
  const response = await api.get(`${ApiEndpoints.COMMUNITY_DROPDOWN}?ToDate=${tomorrow}`);
  return response;
}

export async function GetCommunityVisits(payload) {
  const response = await api.get(
    `${ApiEndpoints.ALL_COMMUNITY_VISITS}?ToDate=${tomorrow}&CommunityId=${payload.communityId}`,
  );
  return response;
}

export async function GetCommunityVisitSummay(payload) {
  const response = await api.get(`${ApiEndpoints.COMMUNITY_VISIT_SUMMARY}/${payload?.id}`);
  return response;
}

export default {
  useCreateCommunityService: (...args) => useMutation(CreateCommunity, ...args),
  useGetAllCommunitiesService: (...args) => useMutation(GetAllCommunities, ...args),
  useFilterAllCommunitiesService: (...args) => useMutation(FilterAllCommunities, ...args),
  useVeiwCommunityService: (...args) => useMutation(ViewCommunity, ...args),
  useViewCommunityMembersService: (...args) => useMutation(ViewCommunityMembers, ...args),
  useAddCommunityMembersService: (...args) => useMutation(AddCommunityMembers, ...args),
  useGetAllPartnerCommunities: (...args) => useMutation(GetAllPartnerCommunities, ...args),
  useViewDevices: (...args) => useMutation(viewDevices, ...args),
};
