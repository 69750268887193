import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetCommuntyStaffService } from '../../../hooks/api/communityService';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const GetCommunityStaffSlice = createSlice({
  name: 'communityStaffs',
  initialState,
  reducers: {
    communnityStaffDefaultState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GetCommunityStaffAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetCommunityStaffAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(GetCommunityStaffAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const GetCommunityStaffAction = createAsyncThunk('communityStaffs', async ({ data }, thunkApi) => {
  // thunkApCi.dispatch(changeProgress(60));
  return GetCommuntyStaffService(data)
    .then((res) => {
      // thunkApi.dispatch(changeProgress(100));

      return res;
    })
    .catch((err) => {
      // thunkApi.dispatch(changeProgress(100));
      console.log(err);

      return thunkApi.rejectWithValue(err);
    });
});

export default GetCommunityStaffSlice.reducer;
export const { communnityStaffDefaultState } = GetCommunityStaffSlice.actions;
