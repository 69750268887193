import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetBanks } from '../../../hooks/api/bankService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const getBanksSlice = createSlice({
  name: 'getBanks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBanksAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBanksAction.fulfilled, (state, action) => {
      let theData = action.payload.map((val) => ({ label: `${val.name}`, value: val.code }));
      state.response = theData;
      state.loading = false;
    });
    builder.addCase(getBanksAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const getBanksAction = createAsyncThunk('getBanks', async (data, thunkApi) => {
  return GetBanks()
    .then((res) => {
      return res;
    })

    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default getBanksSlice.reducer;
