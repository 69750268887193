import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetCommunityVisits } from '../../../hooks/api/communityService';

const initialState = {
  response: null,
  loading: false,
  error: '',
};

const communityVisitSlice = createSlice({
  name: 'communityVisitSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(communityVisitAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(communityVisitAction.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
    });
    builder.addCase(communityVisitAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const communityVisitAction = createAsyncThunk('communityVisitAction', async (data, thunkApi) => {
  return GetCommunityVisits(data)
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err));
});

export default communityVisitSlice.reducer;
