import React from 'react';
import { TbRefresh } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { Loader } from 'rsuite';
import waveLoaderNew from '../../../../../assets/images/Loading-bar-Blue.gif';

export const WaveLoader = ({ text }) => (
  <div className="flex flex-col justify-center items-center text-center">
    <img className="w-12" src={waveLoaderNew} alt="loader" />
    {text !== false && <p className="font-semibold mt-1">Loading...</p>}
  </div>
);

const WorkorderSummary = ({ adminWorkorder, handleRefresh }) => {
  const { loading } = useSelector((state) => state.getAdminWorkorder);
  console.log(loading);
  return (
    <>
      <div className="row-span-1 flex items-center justify-between">
        <p className="text-base font-semibold">Work Order Summary</p>
        <button onClick={handleRefresh} className="p-3 rounded-full hover:bg-slate-100">
          <TbRefresh size={18} />
        </button>
      </div>
      {!loading ? (
        <div className="row-span-2">
          <div className="grid h-full grid-cols-3 gap-3">
            <div className="col-span-1 flex flex-col justify-between p-2 overflow-hidden h-full relative rounded-md shadow">
              <p className="text-xs ">Newly Logged</p>
              <p className="text-xl font-semibold">{adminWorkorder?.newlyLogged}</p>
            </div>
            <div className="col-span-1 flex flex-col justify-between p-2 overflow-hidden h-full relative rounded-md shadow ">
              <p className="text-xs ">Just Started</p>
              <p className="text-xl font-semibold">{adminWorkorder?.start}</p>
            </div>
            <div className="col-span-1 flex flex-col justify-between p-2 overflow-hidden h-full relative rounded-md shadow  bg-white ">
              <p className="text-xs ">Ongoing</p>
              <p className="text-xl font-semibold">{adminWorkorder?.ongoing}</p>
            </div>
            <div className="col-span-1  flex flex-col justify-between p-2 overflow-hidden  h-full relative shadow rounded-md bg-white">
              <p className="text-xs">In Review</p>
              <p className="text-xl font-semibold">{adminWorkorder?.awaitingReview}</p>
            </div>
            <div className="col-span-1 flex flex-col justify-between p-2 overflow-hidden h-full relative rounded-md shadow">
              <p className="text-xs ">Completed</p>
              <p className="text-xl font-semibold">{adminWorkorder?.completed}</p>
            </div>
            <div className="col-span-1 flex flex-col justify-between p-2 overflow-hidden h-full relative rounded-md shadow bg-white">
              <p className="text-sm">Rate(%)</p>
              <p className="text-xl  font-semibold">
                {adminWorkorder?.percentageCompletion.toFixed(2)}%
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-10 grid place-content-center ">
          <Loader />
        </div>
      )}
    </>
  );
};

export default WorkorderSummary;
