import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { FinishWorkorder, ViewMaintenance } from '../../../hooks/api/maintenanceService';
import customToastComponent from '../../../utils/customToast';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const finishWorkorderSlice = createSlice({
  name: 'finishWorkorderSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(finishWorkorderAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(finishWorkorderAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(finishWorkorderAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const finishWorkorderAction = createAsyncThunk('finishWorkorderAction', async (data, thunkApi) => {
  return FinishWorkorder({ workOrderId: data?.workOrderId })
    .then((res) => {
      customToastComponent('Workorder Completed successfully');
      ViewMaintenance(data);
      return res;
    })
    .catch((err) => {
      customToastComponent(err.message, true);
      thunkApi.rejectWithValue(err.message);
    });
});

export default finishWorkorderSlice.reducer;
