import CustomToast from '../customtoast';
import { Modal, Button } from 'rsuite';

const customStyles2 = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
  },

  overlay: { backgroundColor: 'rgba(0,0,0,0.35)' },
};

const largeModal = {
  width: '750px',
  marginInline: 'auto',
  maxWidth: '95%',
};

const DModal = ({ modalOpen, handleClose, children, title, large, titleComponent: TitleComponent }) => {
  return (
    <>
      <Modal backdrop='static' keyboard={false} open={modalOpen} onClose={handleClose} style={large && largeModal}>
        <Modal.Header className="flex justify-between w-full">
          <Modal.Title style={{ fontSize: 20, fontWeight: '500' }}>{title}</Modal.Title>
          {TitleComponent && <Modal.Title style={{ fontSize: 14, fontWeight: 'bold' }}>{TitleComponent}</Modal.Title>}
        </Modal.Header>

        <Modal.Body>{children}</Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default DModal;
