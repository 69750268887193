import axios from 'axios';
import { BASE_URL } from '../config/Endpoints';
import { toast } from 'react-toastify';
// import { store } from '../store';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { GET_STORAGE_ITEM } from './storage';
import { logoutUser } from '../store/slices/Auth/partnerSlice';
import customToastComponent from './customToast';

let store;

export const injectStore = (_store) => {
  store = _store;
};

const getToken = () => {
  return GET_STORAGE_ITEM('token');
};

export const dApis = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'content-type': 'application/json',
    Authorization: getToken() ? `Bearer ${getToken()}` : '',
  },
  timeout: 30000,
});

dApis.interceptors.request.use(function (config) {
  if (navigator.onLine) {
    return config;
  } else {
    customToastComponent('No internet connection', true);
  }
});

dApis.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    if (err.response.status === 401) {
      localStorage.clear();
      store.dispatch(logoutUser());
      window.location.reload();
    } else {
      throw err.response.data;
    }
  },
);

const defaults = {
  headers: () => ({
    'Content-Type': 'application/json',
    Authorization: getToken() ? `Bearer ${getToken()}` : undefined,
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message: 'Something went wrong. Please check your internet connection or contact our support.',
    status: 503,
    data: {},
  },
};

// const api = (method, url, variables) => {

//   if (navigator.onLine) {
//     return new Promise((resolve, reject) => {
//       axios.create({
//         url: `${BASE_URL}${url}`,
//         method,
//         headers: defaults.headers(),
//         params: method === 'get' ? variables : undefined,
//         data: method !== 'get' ? variables : undefined,
//       }).then(
//         (response) => {
//           resolve(response.data);
//         },
//         (error) => {
//           if (error.message) {
//             reject(error.response.data);
//           } else {
//             reject(defaults.error);
//           }
//         },
//       );
//     });
//   } else {
//     // toast.error(`There's no Internet Connection`, {
//     //   theme: 'colored',
//     //   delay: 2000,
//     //   toastId: 'network',
//     // });
//   }
// };

export default {
  get: (...args) => dApis.get(...args),
  post: (...args) => dApis.post(...args),
  put: (...args) => dApis.put(...args),
  patch: (...args) => dApis.patch(...args),
  delete: (...args) => dApis.delete(...args),
};