import api from '../../utils/api';
import { useMutation } from 'react-query';
import { ApiEndpoints } from '../../config/Endpoints';

export async function GetPartnerWalletInfo() {
  const response = await api.get(`${ApiEndpoints.PARTNER_WALLET_INFO}`);
  return response;
}

export async function Verify_bank({ bankcode, account_number }) {
  const response = await api.get(`${ApiEndpoints.VERIFY_BANK}?BankCode=${bankcode}?&AccountNumber=${account_number}`);
  return response;
}

export async function GetDisbursementSetup() {
  const response = await api.get(`${ApiEndpoints.PARTNER_DISBURSEMENT_SETUP}`);
  return response;
}

export async function SetDisbursementSetup(payload) {
  const response = await api.post(`${ApiEndpoints.PARTNER_DISBURSEMENT_SETUP}`, payload);
  return response;
}

export async function DisbursementRequests() {
  const response = await api.get(`${ApiEndpoints.PARTNER_DISBURSEMENT_REQUESTS}`);
  return response;
}

export async function DisbursementApproval(payload) {
  const response = await api.post(`${ApiEndpoints.PARTNER_DISBURSEMENT_APPROVAL}`, payload);
  return response;
}

export async function RequestDisbursement(payload) {
  const response = await api.post(`${ApiEndpoints.PARTNER_REQUEST_DISBURSEMENT}`, payload);
  return response;
}

export default {
  useGetPartnerWalletInfoService: (...args) => useMutation(GetPartnerWalletInfo, ...args),
};
