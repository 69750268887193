import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteCommuntyStaffService } from '../../../hooks/api/communityService';
import { GetCommunityStaffAction } from './getCommunityStaffs';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const UpdateCommunityStaffMangerSlice = createSlice({
  name: 'deleteCommunityStaff',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UpdateCommunityStaffManagerAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(UpdateCommunityStaffManagerAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(UpdateCommunityStaffManagerAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const UpdateCommunityStaffManagerAction = createAsyncThunk(
  'deleteCommunityStaff',
  async ({ data, communityId }, thunkApi) => {
    // thunkApCi.dispatch(changeProgress(60));
    return deleteCommuntyStaffService(data)
      .then((res) => {
        thunkApi.dispatch(GetCommunityStaffAction({ data: communityId }));

        return res;
      })
      .catch((err) => {
        return thunkApi.rejectWithValue(err?.message);
      });
  },
);

export default UpdateCommunityStaffMangerSlice.reducer;
