import api from '../../utils/api';
import { useMutation } from 'react-query';
import { ApiEndpoints } from '../../config/Endpoints';
import moment from 'moment';

export async function CreateCollection(payload) {
  const response = await api.post(`${ApiEndpoints.CREATE_COLLECTION}`, payload);

  return response;
}
export async function GetAllCollection(payload) {
  if (payload.fromDate) {
    const response = await api.get(
      `${ApiEndpoints.ALL_COLLECTION}?PageNumber=${payload.pageIndex ?? 1}&FromDate=${payload.fromDate}&ToDate=${
        payload.toDate ?? moment().add(5, 'minutes').toISOString()
      }`,
    );
    return response;
  } else if (payload.staffID) {
    const response = await api.get(
      `${ApiEndpoints.ALL_COLLECTION}?PageNumber=${payload.pageIndex ?? 1}&ToDate=${
        payload.toDate ?? moment().add(5, 'minutes').toISOString()
      }&StaffID=${payload.staffID}`,
    );
    return response;
  } else {
    const response = await api.get(
      `${ApiEndpoints.ALL_COLLECTION}?PageNumber=${payload.pageIndex ?? 1}&ToDate=${
        payload.toDate ?? moment().add(5, 'minutes').toISOString()
      }`,
    );
    return response;
  }
}
export async function FilterAllCollection(payload) {
  const response = await api.get(
    `${ApiEndpoints.ALL_COLLECTION}?FromDate=${payload?.startDate}&ToDate=${payload?.toDate}&StaffID=${payload?.staffID}`,
  );
  return response;
}
export async function AddCollectionMembers(id, payload) {
  const response = await api.post(`${ApiEndpoints.ADD_COLLECTION_MEMBERS}/${id}`, payload);

  return response;
}
export async function GetCommunnityResidents(id) {
  const response = await api.get(`${ApiEndpoints.GET_PROPERTY_RESIDENT}/${id}`);

  return response;
}
export async function ViewCollectionMembers(payload) {
  const response = await api.get(
    `${ApiEndpoints.VIEW_COLLECTION_MEMBERS}/${payload.id}?PaymentType=${payload.paymentType}`,
  );

  return response;
}
export async function ViewCollection(id) {
  const response = await api.get(`${ApiEndpoints.VIEW_COLLECTION}/${id}`);

  return response;
}
export async function CollectionByUser(phone) {
  const response = await api.get(`${ApiEndpoints.COLLECTION_BY_USER}?phonenumber=${phone}`);

  return response;
}

export default {
  useCreateCollectionService: (...args) => useMutation(CreateCollection, ...args),
  useGetAllCollectionService: (...args) => useMutation(GetAllCollection, ...args),
  useFilterAllCollectionService: (...args) => useMutation(FilterAllCollection, ...args),
  useVeiwCollectionService: (...args) => useMutation(ViewCollection, ...args),
  useViewCollectionMembersService: (...args) => useMutation(ViewCollectionMembers, ...args),
  useAddCollectionMembersService: (...args) => useMutation(AddCollectionMembers, ...args),
  useCollectionByUserService: (...args) => useMutation(CollectionByUser, ...args),
};
