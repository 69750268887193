import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeProgress } from '../toploader/toploaderSlice';
import { ResetPasswordLink } from '../../../hooks/api/userService';
import customToastComponent from '../../../utils/customToast';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const resetPasswordLinkSlice = createSlice({
  name: 'resetPasswordLinkSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetPasswordLinkAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetPasswordLinkAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(resetPasswordLinkAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const resetPasswordLinkAction = createAsyncThunk('resetPasswordLinkAction', async (data, thunkApi) => {
  const { email, history } = data;
  thunkApi.dispatch(changeProgress(60));
  return ResetPasswordLink({ email })
    .then((res) => {
      thunkApi.dispatch(changeProgress(100));
      console.log(res);
      customToastComponent(res?.message);
      history.push(`/new-password?email=${email}`);
      return res;
    })
    .catch((err) => {
      thunkApi.dispatch(changeProgress(100));
      customToastComponent(err?.message, true);
      return thunkApi.rejectWithValue(err.message);
    });
});

export default resetPasswordLinkSlice.reducer;
