import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { MyJobs } from '../../../hooks/api/maintenanceService';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const getMyJobs = createSlice({
  name: 'getMyJobs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMyJobsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMyJobsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getMyJobsAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const getMyJobsAction = createAsyncThunk('getMyJobsAction', (data, thunkApi) => {
  return MyJobs(data)
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default getMyJobs.reducer;
