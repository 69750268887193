import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ChangeI } from '../../../hooks/api/partnerService';
import { ChangeImage } from '../../../hooks/api/userService';
import customToastComponent from '../../../utils/customToast';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  response: null,
  error: null,
};

export const changeImageAction = createAsyncThunk('changeImage', async (data, thunkApi) => {
  thunkApi.dispatch(changeProgress(60));
  return ChangeImage(data)
    .then((res) => {
      thunkApi.dispatch(changeProgress(100));
      customToastComponent(res?.message);
      return res;
    })
    .catch((err) => {
      thunkApi.dispatch(changeProgress(100));
      customToastComponent(err?.message, true);
      return thunkApi.rejectWithValue(err);
    });
});

const changeImageSlice = createSlice({
  name: 'changeImageSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(changeImageAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeImageAction.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
    });
    builder.addCase(changeImageAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default changeImageSlice.reducer;
