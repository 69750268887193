import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Formik, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FcGoogle } from 'react-icons/fc';
import { BsFacebook, BsTwitter } from 'react-icons/bs';
import { authOverlay, logo, logowhite } from '../../../Entryfile/imagepath';
import { Input } from '../../../shared/components/formInputs';
import Button from '../../../shared/components/button';
import useAuthService from '../../../shared/hooks/api/userService';
import CustomToast from '../../../shared/components/customtoast';
import { InputFormikField } from '../../../shared/components/customforminput';
import { CustomSelect2 } from '../../../shared/components/selectinput';
import { useTopLoaderState } from '../../../shared/context';
import { useDispatch, useSelector } from 'react-redux';
import { registerAsyncAction } from '../../../shared/store/slices/Auth/registerSlice';
import AuthPageDesign from '../../../shared/components/misc/AuthPageDesign';
import { MdMarkEmailUnread, MdOutlineClose } from 'react-icons/md';
import { FaPhone } from 'react-icons/fa';
import { HiLockClosed } from 'react-icons/hi';

const getFormProps = () => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    // signupas: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().required('Phone number is required'),
    // signupas: Yup.string().required('Password is required'),
    password: Yup.string().required('Password is required'),
  });

  return {
    initialValues,
    validationSchema,
  };
};

const Register = (props) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.register);
  const handleSubmit = async (values) => {
    const { firstName, lastName, email, phone, password, signupas } = values;
    const payload = {
      firstName,
      lastName,
      username: email,
      deviceToken: 'string',
      address: '',
      email,
      phone,
      password,
      role: 'Partner',
    };

    dispatch(registerAsyncAction(payload)).then((res) => {
      if (res.payload.message === 'User created successfully') {
        push({
          pathname: '/otp',
          state: { email: payload.email, phone: payload.phone },
        });
      }
    });
  };

  return (
    <>
      <div className="flex w-full xl:h-screen">
        <AuthPageDesign action={'Create new account'} />
        <div className="w-full px-0 h-screen overflow-y-scroll md:w-5/12 relative md:static flex flex-col justify-center items-center">
          <Link to="/" className="fixed text-appcolor-900 z-10 right-3 top-6 md:top-3 hover:border border-inset">
            <MdOutlineClose size={20} />
          </Link>
          <div
            className={`absolute  md:hidden inset-0 bg-no-repeat bg-cover`}
            style={{
              background: `url(${authOverlay})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              opacity: 0.4,
            }}
          ></div>
          <div className="w-full relative md:w-[90%] grid md:flex flex-col justify-center items-center">
            <div className="mt-5 md:hidden w-full flex pl-3 sm:pl-10 items-center">
              <img src={logo} className="w-36" alt="logo" />
            </div>
            <div className="w-full md:my-8 py-8 px-3 md:p-0">
              <div className="flex flex-col justify-between md:justify-start mb-3">
                <p className="text-center text-xl md:text-2xl font-bold">Create New Account</p>
                <p className="flex items-center justify-center gap-1 text-base">
                  Already have an account ?
                  <Link to="/" className=" md:text-md text-blue-800 font-semibold hover:text-black">
                    Login
                  </Link>
                </p>
              </div>
              <div style={{}}>
                <Formik
                  onSubmit={handleSubmit}
                  validateOnMount={true}
                  initialValues={getFormProps().initialValues}
                  validationSchema={getFormProps().validationSchema}
                >
                  {({ isSubmitting, isValid }) => (
                    <>
                      <div className="text-center">
                        <div>
                          <div className="flex justify-center"></div>

                          <div>
                            {/* <p className="text-4xl font-extrabold">Welcome to Dweller</p> */}
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <Form style={{ width: '100%' }}>
                        <Input>
                          <div className="grid grid-cols-2 gap-2">
                            <div className="mr-1">
                              <Input.InputField
                                type="text"
                                name="firstName"
                                otherclass="py-2"
                                label={'First Name'}
                                placeholder=""
                              ></Input.InputField>
                              <ErrorMessage
                                className="text-xs text-left -mt-3 text-red-600"
                                component="div"
                                name="firstName"
                              />
                            </div>
                            <div className="ml-1">
                              <Input.InputField
                                type="text"
                                name="lastName"
                                otherclass="py-2"
                                label={'Last Name'}
                                placeholder=""
                              ></Input.InputField>
                              <ErrorMessage
                                className="text-xs text-left -mt-3 text-red-600"
                                component="div"
                                name="lastName"
                              />
                            </div>
                          </div>
                          {/* <InputFormikField
                            name="signupas"
                            type="text"
                            label={'Sign Up As'}
                            placeholder=""
                          >
                            {({ form, field }) => {
                              const { setFieldValue } = form;
                              return (
                                <CustomSelect2
                                  options={[
                                    {
                                      label: 'Facility Company',
                                      value: 'facility',
                                    },
                                    {
                                      label: 'LandLord/Care Taker',
                                      value: 'landlord',
                                    },
                                  ]}
                                  onChangeFunction={(i) => setFieldValue('signupas', i.value)}
                                  placeholder={''}
                                />
                              );
                            }}
                          </InputFormikField> */}
                          <div className="relative">
                            <Input.InputField
                              type="email"
                              name="email"
                              otherclass="py-2"
                              label={'Email'}
                              placeholder=""
                            ></Input.InputField>
                            <ErrorMessage
                              className="text-xs text-left -mt-3 text-red-600"
                              component="div"
                              name="email"
                            />
                            <MdMarkEmailUnread size={19} className="absolute right-3 top-11" />
                          </div>
                          <div className="relative">
                            <Input.InputField
                              type="phone"
                              name="phone"
                              otherclass="py-2"
                              label={'Phone'}
                              placeholder=""
                            ></Input.InputField>
                            <ErrorMessage
                              className="text-xs text-left -mt-3 text-red-600"
                              component="div"
                              name="phone"
                            />
                            <FaPhone className="absolute top-11 right-3" size={18} />
                          </div>
                          <div className="relative">
                            <Input.InputField
                              type="password"
                              name="password"
                              otherclass="py-2"
                              label={'Password'}
                              placeholder=""
                            ></Input.InputField>
                            <ErrorMessage
                              className="text-xs text-left -mt-3 text-red-600"
                              component="div"
                              name="password"
                            />
                            <HiLockClosed className="absolute right-3 top-11" size={19} />
                          </div>
                          <div>
                            <Button
                              type="submit"
                              disabled={loading || !isValid}
                              isLoading={loading}
                              style={{ width: '100%', letterSpacing: '1px', borderRadius: 3 }}
                            >
                              Create Account{' '}
                            </Button>
                          </div>
                        </Input>
                        <div style={{ height: '0.5px' }}></div>
                      </Form>
                    </>
                  )}
                </Formik>
                {/* <div className="w-full my-2 text-center flex justify-center items-center">
                  {' '}
                  <span className="text-sm text-grey-200">---------------</span>{' '}
                  <span className="text-lg mx-2 font-bold">or</span>
                  <span className="text-sm text-grey-200">---------------</span>{' '}
                </div>
                <div className="flex flex-col justify-around">
                  <div className="border-solid p-3 my-2 border-2 rounded shadow-md flex items-center justify-center ">
                    <FcGoogle size={24} />
                    <span className="ml-2 text-base font-semibold">Sign up with Google</span>
                  </div>
                  <div className="border-solid p-3 my-2 border-2 rounded shadow-md flex  items-center justify-center ">
                    <BsFacebook size={24} color="#1a2b46" />
                    <span className="ml-2 text-base font-semibold">Sign up with Facebook</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <CustomToast />
      </div>
    </>
  );
};

export default Register;
