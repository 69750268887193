import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ViewCollectionMembers } from '../../../hooks/api/collectionService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const getCollectionMembersByIDSlice = createSlice({
  name: 'getCollectionMembersByID',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCollectionMembersByIDAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollectionMembersByIDAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(getCollectionMembersByIDAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const getCollectionMembersByIDAction = createAsyncThunk(
  'getCollectionMembersByIDAction',
  async ({ data }, thunkApi) => {
    return ViewCollectionMembers(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return thunkApi.rejectWithValue(err.message);
      });
  },
);

export default getCollectionMembersByIDSlice.reducer;
