import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authOverlay, logo } from '../../../Entryfile/imagepath';
import { Input } from '../../../shared/components/formInputs';
import Button from '../../../shared/components/button';
import CustomToast, { CustomSuccessToast } from '../../../shared/components/customtoast';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, reset } from '../../../shared/store/slices/Auth/loginSlice';
import { partnerSignInAction } from '../../../shared/store/slices/Auth/partnerSlice';
import { MdMarkEmailUnread, MdOutlineClose, MdOutlineSendToMobile } from 'react-icons/md';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { HiLockOpen } from 'react-icons/hi';
import AuthPageDesign from '../../../shared/components/misc/AuthPageDesign';
import { resetPasswordLinkAction } from '../../../shared/store/slices/user/resetPasswordLink';

const getFormProps = () => {
  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
  });

  return {
    initialValues,
    validationSchema,
  };
};

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const { loading } = useSelector((state) => state.resetPasswordLink);

  async function handleSubmit(values) {
    const { email } = values;

    dispatch(resetPasswordLinkAction({ email, history }));
  }

  return (
    <>
      <div className="flex md:flex-row flex-col w-full xl:h-screen 3xl:overflow-y-hidden">
        <AuthPageDesign action={'Login to your account'} />
        <div className="w-full px-3 md:px-0 md:w-5/12 h-screen flex flex-col relative justify-center items-center">
          <Link to="/" className="fixed text-appcolor-900 z-10 right-3 top-6 md:top-3 hover:border border-inset">
            <MdOutlineClose size={20} />
          </Link>
          <div
            className={`absolute  md:hidden inset-0 bg-no-repeat bg-cover`}
            style={{
              background: `url(${authOverlay})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              opacity: 0.4,
            }}
          ></div>

          <div className="w-full md:w-[90%] relative max-w-[500px] h-full flex flex-col justify-center items-center">
            <div className="absolute top-0 left-0 mt-4 md:hidden flex items-center">
              <img src={logo} className="w-36 h-10" />
            </div>

            <div
              className=" w-full rounded py-8 px-3 md:p-0 bg-white md:shadow-none md:bg-transparent"
              style={{ marginTop: '30px', marginBottom: '50px' }}
            >
              <div className="mt-10 md:mt-[unset] flex justify-between md:justify-start mb-5">
                <div className="px-4">
                  <p className="text-xl md:text-2xl md:mb-2 mb-1 font-bold"> Forgot your password?</p>
                  <p className="text-sm text-appcolor-400">Don't panic. We've got you covered.</p>{' '}
                </div>
              </div>
              <div>
                <Formik
                  onSubmit={handleSubmit}
                  validateOnMount={true}
                  initialValues={getFormProps().initialValues}
                  validationSchema={getFormProps().validationSchema}
                >
                  {({ isSubmitting, isValid }) => (
                    <>
                      <div className="text-center">
                        <div className="">
                          <div className="flex justify-center"></div>
                          <div>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <Form style={{ width: '100%' }}>
                        <Input>
                          <div className="relative">
                            <Input.InputField
                              type="email"
                              name="email"
                              otherclass="py-2.5 !text-sm mb-2"
                              label={'Email address'}
                              placeholder=""
                            ></Input.InputField>
                            <ErrorMessage
                              className="-mt-2.5 text-xs text-left text-red-600"
                              component="div"
                              name="email"
                            />
                            <MdMarkEmailUnread className="absolute right-3 top-11" size={20} color="#23395d" />
                          </div>
                          <p className="mt-3">
                            Please enter the email you used while creating your dweller account. We'll send you a
                            confirmation code and guide you through the reminaing process.
                          </p>
                          <div className="pt-3">
                            <Button
                              className="flex"
                              type="submit"
                              disabled={isSubmitting || !isValid || loading}
                              isLoading={isSubmitting || loading}
                              style={{
                                width: '100%',
                                gap: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'centers',
                              }}
                            >
                              <MdOutlineSendToMobile size={20} />
                              <span>Send Link</span>
                            </Button>
                            <p className="text-center mt-3">
                              New to dweller?{' '}
                              <Link className="font-semibold text-appcolor-400 underline" to="/signup">
                                Sign up
                              </Link>{' '}
                            </p>
                          </div>
                        </Input>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomToast />
    </>
  );
};

export default ResetPassword;
