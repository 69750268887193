import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreatePartner } from '../../../hooks/api/partnerService';
import { SET_STORAGE_ITEM } from '../../../utils/storage';
import { changeLoading } from '../Auth/loginSlice';
import { partnerSignInAction } from '../Auth/partnerSlice';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const createPartnerSlice = createSlice({
  name: 'createPartner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPartnerAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPartnerAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(createPartnerAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const createPartnerAction = createAsyncThunk('createPartner', async ({ data, history }, thunkApi) => {
  thunkApi.dispatch(changeProgress(60));

  return CreatePartner(data)
    .then((res) => {
      thunkApi.dispatch(changeProgress(100));
      if (res?.status?.value === 6) {
        history.push('/unverified');

        thunkApi.rejectWithValue(res.message);
        localStorage.clear();
      }
      // SET_STORAGE_ITEM('partner', {
      //   logo: res.partner.companyLogo,
      //   name: res.partner.name,
      //   id: res.partner.id,
      //   type: res.partner.partnerType.label,
      //   roleDefination: res.roleDefination,
      // });
      // SET_STORAGE_ITEM('currentUser', {
      //   name: res.user.firstName + ' ' + res.user.lastName,
      //   id: res.user.id,
      //   email: res.user.email,
      // });
      // SET_STORAGE_ITEM('token', res.token);

      return res;
    })
    .catch((err) => {
      thunkApi.dispatch(changeProgress(100));
      return thunkApi.rejectWithValue(err.message);
    });
});

export default createPartnerSlice.reducer;
