import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { viewDevices } from '../../../hooks/api/communityService';

const initialState = {
  response: null,
  loading: false,
  error: '',
};

const viewDevicesSlice = createSlice({
  name: 'viewDevices',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(viewDevicesAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(viewDevicesAction.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
    });

    builder.addCase(viewDevicesAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const viewDevicesAction = createAsyncThunk('viewDevices', async (data, thunkApi) => {
  return viewDevices(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return thunkApi.rejectWithValue(error);
    });
});

export default viewDevicesSlice.reducer;
