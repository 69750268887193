import { lazy } from 'react';
import Login from '../pages/Auth/Login/index';
import NewPassword from '../pages/Auth/NewPassword';
import Otp from '../pages/Auth/otp';
import { RedirectComponent } from '../pages/Auth/Redirect';
import Register from '../pages/Auth/Register/index';
import ResetPassword from '../pages/Auth/ResetPassword';
import UnverifiedAccount from '../pages/Auth/UnverifiedAccount';
// import DefaultLayout from '../shared/navs/DefaultLayout';
import ThePartnerForm from '../pages/createPartner/index';

// const Login = lazy(() => import('../pages/Auth/Login/index'));
// const Register = lazy(() => import('../pages/Auth/Register/index'));
// const Otp = lazy(() => import('../pages/Auth/otp/index'));
// const ForgotPassword = lazy(() => import("../pages/Auth/ForgotPassword"));
// const OTP = lazy(() => import("../pages/Auth/VerifyOtp"));
const DefaultLayout = lazy(() => import('../shared/navs/DefaultLayout'));
// const Error = lazy(() => import("../pages/MainPage/Error/Error"));

const routes = [
  {
    path: '/',
    exact: true,
    // component: Login,
    component: RedirectComponent,
    guarded: false,
  },
  {
    path: '/signup',
    component: Register,
    guarded: false,
  },
  {
    path: '/otp',
    component: Otp,
    guarded: false,
  },
  {
    path: '/unverified',
    component: UnverifiedAccount,
    guarded: false,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    guarded: false,
  },
  {
    path: '/new-password',
    component: NewPassword,
    guarded: false,
  },

  // {
  //   path: "/verify-otp",
  //   component: OTP,
  //   guarded: false,
  // },
  // {
  //   path: "/error",
  //   component: Error,
  //   guarded: false,
  // },
  {
    path: '/create-partner',
    component: ThePartnerForm,
    guarded: true,
  },
  {
    path: '/app',
    component: DefaultLayout,
    guarded: true,
  },
];

export { routes };
