import api from '../../utils/api';
import { useMutation } from 'react-query';
import { ApiEndpoints } from '../../config/Endpoints';

const today = new Date();
//using tomorrow's date, so we don't get back stale data on query invalidation
let tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);
tomorrow = new Date(tomorrow).toISOString();


const currentYear = new Date().toISOString();

export async function PartnerSignIn(data) {
  const response = await api.post(`${ApiEndpoints.PARTNER_SIGNIN}`, data);

  return response;
}

export async function UploadImage(payload) {
  const response = await api.post(ApiEndpoints.UPLOAD_IMAGE, payload);
  return response;
}

export async function PartnerDetail() {
  const response = await api.get(`${ApiEndpoints.PARTNER_DETAILS}`);
  return response;
}

export async function PartnerAccount() {
  const response = await api.get(`${ApiEndpoints.GET_PARTNER_ACCOUNT}`);
  return response;
}

export async function PartnerAllStaff(payload) {
  const response = await api.get(
    `${ApiEndpoints.ALL_STAFFS}?PartnerMemberRoleType=${
      payload?.PartnerMemberRoleType ? parseInt(payload.PartnerMemberRoleType) : ''
    }&IsActive=${payload?.IsActive}&Name=${payload?.Name || ''}`,
  );
  return response;
}

export async function SearchUser(query) {
  const response = await api.get(`${ApiEndpoints.SEARCH_USER}?keyword=${query}`);

  return response;
}

export async function MyCommunities(query) {
  const response = await api.get(`${ApiEndpoints.MY_COMMUNITIES}`);
  return response;
}

export async function CreatePartner(payload) {
  const response = await api.post(`${ApiEndpoints.CREATE_PARTNER}`, payload);
  return response;
}

export async function AddStaff(payload) {
  const response = await api.post(`${ApiEndpoints.ADD_STAFFS}`, payload);
  return response;
}

export async function AddPartnerBank(payload) {
  const response = await api.post(`${ApiEndpoints.ADD_PARTNER_BANK_ACCOUNT}`, payload);
  return response;
}

export async function GetAllPartnerProperty(payload) {
  const response = await api.get(
    `${ApiEndpoints.ALL_PARTNER_PROPERTY}?PageNumber=${payload?.pageIndex}&ToDate=${currentYear}&Address=${
      payload?.address || ''
    }`,
  );
  return response;
}

export async function GetPartnerStaffDetails(payload) {
  const response = await api.get(`${ApiEndpoints.STAFF_DETAILS}/${payload?.id}`);
  return response;
}

export async function GetUserCommunities(payload) {
  const response = await api.get(`${ApiEndpoints.STAFF_COMMUNITIES}/${payload.id}`);
  return response;
}

export async function GetSTaffSummary() {
  const response = await api.get(`${ApiEndpoints.STAFF_SUMMARY}`);
  return response;
}

export async function UpdateMemberPermission(payload) {
  const response = await api.patch(`${ApiEndpoints.UPDATE_MEMBER_PERMISSION}/${payload.id}`, payload.permission);
  return response;
}

export async function UpdateMember(payload) {
  const response = await api.patch(`${ApiEndpoints.UPDATE_MEMBER}/${payload.id}`, payload.data);
  return response;
}

export async function StaffDropdown() {
  const response = await api.get(`${ApiEndpoints.STAFF_DROPDOWN}`);
  return response;
}

export async function PartnerBonus() {
  const response = await api.get(`${ApiEndpoints.PARTNER_BONUS_ACCOUNT}`);
  return response;
}

export async function PartnerTransactions(payload) {
  const response = await api.get(`${ApiEndpoints.PARTNER_TRANSACTIONS}?ToDate=${tomorrow}&PageNumber=${payload?.pageIndex}`);
  return response;
}

export default {
  usePartnerSignInService: (...args) => useMutation(PartnerSignIn, ...args),
  usePartnerImageUploadService: (...args) => useMutation(UploadImage, ...args),
  usePartnerDetailService: (...args) => useMutation(PartnerDetail, ...args),
  useCreatePartnerService: (...args) => useMutation(CreatePartner, ...args),
  usePartnerUserSearchService: (...args) => useMutation(SearchUser, ...args),
  usePartnerCommunitiesService: (...args) => useMutation(MyCommunities, ...args),
  useGetAllPartnerStaffsService: (...args) => useMutation(PartnerAllStaff, ...args),
  useAddPartnerBankAccountService: (...args) => useMutation(AddPartnerBank, ...args),
  useGetAllPartnerProperty: (...args) => useMutation(GetAllPartnerProperty, ...args),
  useGetPartnerStaffDetails: (...args) => useMutation(GetPartnerStaffDetails, ...args),
  useGetUserCommunities: (...args) => useMutation(GetUserCommunities, ...args),
  useGetStaffSummary: (...args) => useMutation(GetSTaffSummary, ...args),
  useUpdateMemberPermission: (...args) => useMutation(UpdateMemberPermission, ...args),
  useUpdateMember: (...args) => useMutation(UpdateMember, ...args),
};
