import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loading } from '../../../Entryfile/RouteGuard';
import Login from '../Login';

const rootDashboardUrl = `${window.location.origin}/app/dashboard`;
const RedirectComponent = () => {
  const [loading, setLoading] = useState(true);
  const { currentUser, partnerObject } = useSelector((state) => state.partner);

  useEffect(() => {
    (function () {
      if (partnerObject) {
        window.location.href = rootDashboardUrl;
      } else {
        setLoading(false);
      }
    })();
  }, []);
  return loading ? <Loading logo={true} /> : <Login />;
};

export { RedirectComponent };
