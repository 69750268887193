import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetMyServiceRequestSummary } from '../../../hooks/api/maintenanceService';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const getMyServiceRequestSummarySlice = createSlice({
  name: 'getMyServiceRequestSummary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMyServiceRequestSummaryAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMyServiceRequestSummaryAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getMyServiceRequestSummaryAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const getMyServiceRequestSummaryAction = createAsyncThunk(
  'getMyServiceRequestSummaryAction',
  (data, thunkApi) => {
    return GetMyServiceRequestSummary(data)
      .then((res) => res)
      .catch((err) => thunkApi.rejectWithValue(err.message));
  },
);

export default getMyServiceRequestSummarySlice.reducer;
