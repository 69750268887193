import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReportStaffComm } from '../../../hooks/api/reportService';

const initialState = {
  loading: true,
  error: null,
  data: {},
};

const getStaffCommSlice = createSlice({
  name: 'getStaffCommSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStaffCommAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStaffCommAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getStaffCommAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const getStaffCommAction = createAsyncThunk('getStaffCommAction', async (data, thunkApi) => {
  return ReportStaffComm(data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default getStaffCommSlice.reducer;
