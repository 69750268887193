import { configureStore } from '@reduxjs/toolkit';
import { NoNetworkFunc, toastFunc } from '../utils/middleWare';
import loginReducer from './slices/Auth/loginSlice';
import partnerReducer from './slices/Auth/partnerSlice';
import registerReducer from './slices/Auth/registerSlice';
import otpSlice from './slices/Auth/otpSlice';
import imageUploadSlice from './slices/image/imageUploadSlice';
import createPartnerSlice from './slices/partner/createPartnerSlice';
import getBanksSlice from './slices/partner/getBankSlice';
import verifyBanksSlice from './slices/partner/verifyBankSlice';
import addPartnerBanksSlice from './slices/partner/addPartnerBankSlice';
import toploaderSlice from './slices/toploader/toploaderSlice';
import CreateCollectionSlice from './slices/collection/createCollectionSlice';
import collectionByUserSlice from './slices/collection/collectionByUserSlice';
import getAllCollectionSlice from './slices/collection/getAllCollection';
import viewCollectionSlice from './slices/collection/viewCollection';
import addcollectionMembersSlice from './slices/collection/addMoreCollectionMembers';
import getCollectionMembersByIDSlice from './slices/collection/getCollectionMembersByID';
import createCommunitySlice from './slices/community/createCommunitySlice';
import getPartnerCommunitySlice from './slices/community/partnerCommunities';
import getStaffCommunitySlice from './slices/community/staffCommunities';
import partnerStaffsSlice from './slices/partner/partnerStaffs';
import getStatesSlice from './slices/states_regions/getStatesSlice';
import getRegionsSlice from './slices/states_regions/getRegionsSlice';
import getSubRegionsSlice from './slices/states_regions/getSubRegionsSlice';
import getCommunityPropertySlice from './slices/property/communityPropertySlice';
import getCollectionReportSlice from './slices/report/collectionReportSlice';
import getCollectionReport_StaffSlice from './slices/report/collectionReport_StaffSlice';
import getPropertyReportSlice from './slices/report/propertyReportSlice';
import getCommunityManagementReportSlice from './slices/report/communityManagementReport';
import partnerCommunitySlice from './slices/partner/partnerCommunities';
import partnerUserSearchSlice from './slices/partner/partnerUsersearch';
import getWalletInfoSlice from './slices/finance/walletInfoSlice';
import uploadBulkPropertySlice from './slices/property/uploadBulkProperty';
import changePasswordSlice from './slices/user/changePassword';
import updateUserSlice from './slices/user/updateUser';
import partnerAddStaffSlice from './slices/partner/addStaff';
import uploadBulkResidentSlice from './slices/property/uploadBulkResident';
import getCommunityResidentSlice from './slices/property/communityResidentSlice';
import getActivityLogs from './slices/report/activityLogSlice';
import getAdminWorkorder from './slices/report/adminWorkorder';
import communitySummarySlice from './slices/community/communitySummary';
import residentSummarySlice from './slices/community/residentSummary';
import propertySummarySlice from './slices/community/propertySummary';
import CommunityDetailsSlice from './slices/community/communityDetails';
import UpdateCommunitySlice from './slices/community/updateCommunity';
import GetCommunityStaffSlice from './slices/community/getCommunityStaffs';
import UpdateCommunityStaffMangerSlice from './slices/community/updateCommunityStaffsManager';
import DeleteCommunityStaffSlice from './slices/community/deleteCommunityStaffs';
import AssignStaffToCommunitySlice from './slices/community/assignStaffToCommunity';
import getStaffWorkorder from './slices/report/staffWorkorder';
import partnerStaffDetails from './slices/partner/staffDetailSlice';
import getStaffComm from './slices/report/StaffCommSlice';
import staffSummarySlice from './slices/partner/staffSummarySlice';
import allServiceRequestSlice from './slices/maintenance/allServiceRequestSlice';
import createServiceRequestSlice from './slices/maintenance/createServiceRequestSlice';
import allMaintenance from './slices/maintenance/allMaintenance';
import myJobs from './slices/maintenance/myJobs';
import allMaintenanceSummary from './slices/maintenance/allMaintenanceSummary';
import myMaintenanceSummary from './slices/maintenance/myMaintenanceSummary';
import myServiceRequestSummary from './slices/maintenance/myServiceRequestSummary';
import allServiceRequestSummary from './slices/maintenance/allServiceRequestSummary';
import viewMaintenance from './slices/maintenance/viewMaintenance';
import workorderActivity from './slices/maintenance/workorderActivitySlice';
import assetDropdown from './slices/dropdown/assetDropdownSlice';
import communityDropdown from './slices/dropdown/communityDropdownSlice';
import propertyDropdown from './slices/dropdown/propertyDropdownSlice';
import residentDropdown from './slices/dropdown/residentDropdownSlice';
import staffDropdown from './slices/dropdown/staffDropdownSlice';
import createMaintenance from './slices/maintenance/createMaintenance';
import viewWorkOrderStaff from './slices/maintenance/viewWorkOrderStaff';
import viewMaintenanceItems from './slices/maintenance/viewMaintenanceItems';
import viewPropertySlice from './slices/property/viewProperty';
import updateWorkorderItems from './slices/maintenance/updateWorkorderItemSlice';
import updateWorkorderStaff from './slices/maintenance/updateWorkorderStaffSlice';
import approveWorkorder from './slices/maintenance/approveWorkorderSlice';
import startWorkorder from './slices/maintenance/startWorkorderSlice';
import finishWorkorder from './slices/maintenance/finishWorkorderSlice';
import getCommunityResidentIdSlice from './slices/property/getCommunityResidentIdSlice';
import residentActivityLogSlice from './slices/property/residentActivityLogSlice';
import addResidentDocumentSlice from './slices/property/addResidentDocumentSlice';
import viewResidentDetailTableSlice from './slices/property/viewResidentDetailTableSlice';
import addResidentRestrictionSlice from './slices/property/addRestrictionSlice';
import commentSlice from './slices/maintenance/commentWorkorder';
import addNotificationDataSlice from './slices/property/addNotificationDataSlice';
import getNotificationSlice from './slices/property/getNotificationSlice';
import viewDevicesSlice from './slices/community/viewDevicesSlice';
import partnerBonus from './slices/partner/partnerBonus';
import getUser from './slices/user/getUser';
import partnerDetails from './slices/partner/partnerDetails';
import resetPasswordLink from './slices/user/resetPasswordLink';
import resetPassword from './slices/user/resetPassword';
import changeImageSlice from './slices/user/changeImageSlice';
import communityVisitSummary from './slices/community/communityVisitSummary';
import communityVisit from './slices/community/communityVisit';
import partnerAccounts from './slices/partner/partnerAccounts';
import removeRestrictionSlice from './slices/property/removeRestrictionSlice';
import subResident from './slices/partner/subResident';
import partnerTransactions from './slices/partner/partnerTransactions';
import getDisbursement from './slices/finance/getDisbursement';
import disbursementRequests from './slices/finance/disbursementRequests';
import disbursementApproval from './slices/finance/disbursementApproval';
import requestDisbursement from './slices/finance/requestDisbursement';
import setDisbursement from './slices/finance/setDisbursement';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    topLoader: toploaderSlice,
    partner: partnerReducer,
    register: registerReducer,
    otp: otpSlice,
    imageUpload: imageUploadSlice,
    createPartner: createPartnerSlice,
    getBanks: getBanksSlice,
    verifyBanks: verifyBanksSlice,
    addPartnerBank: addPartnerBanksSlice,
    createCollection: CreateCollectionSlice,
    collectionByUser: collectionByUserSlice,
    partnerStaffs: partnerStaffsSlice,
    createCommunity: createCommunitySlice,
    getresidentActivityLog: residentActivityLogSlice,
    getResidentDetailTable: viewResidentDetailTableSlice,
    getaddResidentDocument: addResidentDocumentSlice,
    getaddRestriction: addResidentRestrictionSlice,
    getAllCollection: getAllCollectionSlice,
    getCollectionMembersByID: getCollectionMembersByIDSlice,
    getPartnerCommunity: getPartnerCommunitySlice,
    getStaffCommunity: getStaffCommunitySlice,
    getStates: getStatesSlice,
    getRegions: getRegionsSlice,
    getSubRegions: getSubRegionsSlice,
    getCommunityProperty: getCommunityPropertySlice,
    getCollectionReport: getCollectionReportSlice,
    getCollectionReport_Staff: getCollectionReport_StaffSlice,
    getPropertyReport: getPropertyReportSlice,
    getCommunityManagementReport: getCommunityManagementReportSlice,
    partnerCommunity: partnerCommunitySlice,
    partnerUserSearch: partnerUserSearchSlice,
    getWalletInfo: getWalletInfoSlice,
    uploadBulkProperty: uploadBulkPropertySlice,
    changePassword: changePasswordSlice,
    updateUser: updateUserSlice,

    getCommunityResidentId: getCommunityResidentIdSlice,
    getaddNotification: addNotificationDataSlice,
    getNotificationData: getNotificationSlice,
    viewDevices: viewDevicesSlice,

    viewCollection: viewCollectionSlice,
    addcollectionMembers: addcollectionMembersSlice,
    partnerAddStaff: partnerAddStaffSlice,
    uploadBulkResident: uploadBulkResidentSlice,
    getCommunityResident: getCommunityResidentSlice,
    communitySummary: communitySummarySlice,
    residentSummary: residentSummarySlice,
    propertySummary: propertySummarySlice,
    CommunityDetails: CommunityDetailsSlice,
    updateCommunity: UpdateCommunitySlice,
    getCommunityStaff: GetCommunityStaffSlice,
    updateCommunityStaffManger: UpdateCommunityStaffMangerSlice,
    deleteCommunityStaff: DeleteCommunityStaffSlice,
    assignStaffToCommunity: AssignStaffToCommunitySlice,
    viewProperty: viewPropertySlice,
    getActivityLogs,
    getAdminWorkorder,
    getStaffWorkorder,
    partnerStaffDetails,
    getStaffComm,
    staffSummary: staffSummarySlice,
    serviceRequestTypes: allServiceRequestSlice,
    createServiceRequest: createServiceRequestSlice,
    allMaintenance,
    allMaintenanceSummary,
    allServiceRequestSummary,
    myMaintenanceSummary,
    myServiceRequestSummary,
    myJobs,
    viewMaintenance,
    workorderActivity,
    assetDropdown,
    communityDropdown,
    propertyDropdown,
    residentDropdown,
    staffDropdown,
    createMaintenance,
    viewWorkOrderStaff,
    viewMaintenanceItems,
    updateWorkorderItems,
    updateWorkorderStaff,
    approveWorkorder,
    startWorkorder,
    finishWorkorder,
    commentWorkorder: commentSlice,
    partnerBonus,
    getUser,
    partnerDetails,
    partnerAccounts,
    resetPassword,
    resetPasswordLink,
    changeImage: changeImageSlice,
    communityVisitSummary,
    communityVisit,
    removeRestriction: removeRestrictionSlice,
    subResident,
    partnerTransactions,
    getDisbursement,
    setDisbursement,
    disbursementRequests,
    disbursementApproval,
    requestDisbursement,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(toastFunc, NoNetworkFunc);
  },
});
