import { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import DModal from '../shared/components/dmodal';
import WelcomeModal from '../shared/components/misc/WelcomeModal';
import { GET_STORAGE_ITEM } from '../shared/utils/storage';
import waveLoader from '../assets/icons/wave-1-unscreen.gif';
import dlogo from '../assets/images/dweller-logo.png';
import { WaveLoader } from '../pages/MainPage/dashboard/Admin/workorderSummary';

const RouteGuard = ({ who = ['user'], ...props }) => {
  const [showModal, setShowModal] = useState(true);
  const handleClose = () => setShowModal(false);

  const token = GET_STORAGE_ITEM('token');
  return token ? (
    <>
      <Route path={props.path} component={props.component} />
      {/* <DModal modalOpen={showModal} handleClose={handleClose}>
        <WelcomeModal modalOpen={showModal} handleClose={handleClose} />
      </DModal> */}
    </>
  ) : (
    <Redirect to={'/'} />
  );
};

function Loading({ logo = false, children }) {
  return (
    <main className="section h-screen w-screen flex justify-center items-center">
      {children ? children : <WaveLoader text={false} />}
      {/* <section className="container">
        <div className="row align-items-center justify-content-center vh-100">
          <div className="column is-fullwidth is-offset-1">
            <div className=''>
            </div>
          </div>
        </div>
      </section> */}
    </main>
  );
}

export { RouteGuard, Loading };
