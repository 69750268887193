import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AddCollectionMembers } from '../../../hooks/api/collectionService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const addcollectionMembersSlice = createSlice({
  name: 'addcollectionMembersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addcollectionMembersAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addcollectionMembersAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(addcollectionMembersAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const addcollectionMembersAction = createAsyncThunk(
  'addcollectionMembersAction',
  async ({ id, data }, thunkApi) => {
    thunkApi.dispatch(changeProgress(60));
    return AddCollectionMembers(id, data)
      .then((res) => {
        thunkApi.dispatch(changeProgress(100));
        return res;
      })
      .catch((err) => {
        thunkApi.dispatch(changeProgress(100));
        return thunkApi.rejectWithValue(err.message);
      });
  },
);

export default addcollectionMembersSlice.reducer;
