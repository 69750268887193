import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PartnerAccount } from '../../../hooks/api/partnerService';

const initialState = {
  loading: false,
  response: {},
  error: '',
};

const partnerAccountSlice = createSlice({
  name: 'partnerAccountSlice',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(partnerAccountAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(partnerAccountAction.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
    });
    builder.addCase(partnerAccountAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const partnerAccountAction = createAsyncThunk('partnerAccountAction', async (thunkApi) => {
  return PartnerAccount()
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default partnerAccountSlice.reducer;
