import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ResidentActivityLog } from '../../../hooks/api/reportService';

const initialState = {
  data: [],
  error: '',
  loading: false,
};

const residentActivityLogSlice = createSlice({
  name: 'residentactivityLogSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(residentActivityLogsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(residentActivityLogsAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(residentActivityLogsAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const residentActivityLogsAction = createAsyncThunk('residentactivityLogAction', async (payload, thunkApi) => {
  return ResidentActivityLog(payload)
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default residentActivityLogSlice.reducer;
