import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Verify_bank } from '../../../hooks/api/bankService';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const verifyBanksSlice = createSlice({
  name: 'verifyBank',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(verifyBanksAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyBanksAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(verifyBanksAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const verifyBanksAction = createAsyncThunk('verifyBanks', async (data, thunkApi) => {
  return Verify_bank(data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default verifyBanksSlice.reducer;
