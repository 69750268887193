import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReportAdminWorkorder } from '../../../hooks/api/reportService';

const initialState = {
  loading: true,
  error: null,
  response: null,
};

const getAdminWorkorderSlice = createSlice({
  name: 'getAdminWorkorder',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(adminWorkorderAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(adminWorkorderAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(adminWorkorderAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const adminWorkorderAction = createAsyncThunk('adminWorkorderAction', async (data, thunkApi) => {
  return ReportAdminWorkorder(data)
    .then((res) => {
      return res;
    })

    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default getAdminWorkorderSlice.reducer;
