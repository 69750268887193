import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { VerifyOtpUser } from '../../../hooks/api/userService';
import { dApis } from '../../../utils/api';
import { SET_STORAGE_ITEM } from '../../../utils/storage';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  error: null,
  token: null,
};

const otpSlice = createSlice({
  name: 'verifyOtp',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(verifyOtpAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyOtpAction.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.loading = false;
    });
    builder.addCase(verifyOtpAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const verifyOtpAction = createAsyncThunk('verifyOtpAction', async ({ data, history }, thunkApi) => {
  thunkApi.dispatch(changeProgress(60));
  return VerifyOtpUser(data)
    .then((res) => {
      SET_STORAGE_ITEM('token', res.token);
      dApis.defaults.headers['Authorization'] = `Bearer ${res.token}`;

      thunkApi.dispatch(changeProgress(100));
      history.push('/create-partner');
      return res;
    })
    .catch((error) => {
      thunkApi.dispatch(changeProgress(100));

      return thunkApi.rejectWithValue(error);
    });
});

export default otpSlice.reducer;
