import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addNotification } from '../../../hooks/api/propertyService';
import { getNotificationAction } from './getNotificationSlice';

const initialState = {
  response: null,
  formData: {},
  loading: false,
  error: null,
};

const addNotificationDataSlice = createSlice({
  name: 'notificationData',
  initialState: initialState,

  reducers: {
    saveData: (state, action) => {
      state.formData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addNotificationAction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addNotificationAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });

    builder.addCase(addNotificationAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});
export const addNotificationAction = createAsyncThunk('addNotification', async (data, thunkApi) => {

  return addNotification(data)
    .then((res) => {
      // thunkApi.dispatch(
      //   getNotificationAction({
      //     communityId: data,
      //   }),
      // );
      return res;
    })

    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default addNotificationDataSlice.reducer;

export const { saveData } = addNotificationDataSlice.actions;
