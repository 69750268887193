import moment from 'moment';
import { ApiEndpoints } from '../../config/Endpoints';
import api from '../../utils/api';
import { useMutation } from 'react-query';

// const currentYear = new Date().toISOString();

export async function getCommunityProperty(payload) {
  if (payload?.fromDate) {
    const response = await api.get(
      `${ApiEndpoints.COMMUNITY_PROPERTY}?CommunityId=${payload.communityId}&fromDate=${payload?.fromDate}&ToDate=${
        payload.toDate ?? moment().toISOString()
      }&pageNumber=${payload.pageIndex ?? 1}&pageSize=${payload.pageSize ?? 10}`,
    );

    return response;
  } else {
    const response = await api.get(
      `${ApiEndpoints.COMMUNITY_PROPERTY}?CommunityId=${payload.communityId}&ToDate=${
        payload.toDate ?? moment().toISOString()
      }&pageNumber=${payload.pageIndex ?? 1}&pageSize=${payload.pageSize ?? 10}`,
    );

    return response;
  }
}

export async function getCommunityResident(payload) {
  if (payload?.fromDate) {
    const response = await api.get(
      `${ApiEndpoints.COMMUNITY_RESIDENTS}?CommunityId=${payload.communityId}&fromDate=${payload?.fromDate}&ToDate=${
        payload.toDate ?? moment().toISOString()
      }&pageNumber=${payload.pageIndex ?? 1}&pageSize=${payload.pageSize ?? 10}`,
    );
    return response;
  } else {
    const response = await api.get(
      `${ApiEndpoints.COMMUNITY_RESIDENTS}?CommunityId=${payload.communityId}&fromDate=${payload?.fromDate}&ToDate=${
        payload.toDate ?? moment().toISOString()
      }&pageNumber=${payload.pageIndex ?? 1}&pageSize=${payload.pageSize ?? 10}`,
    );
    return response;
  }
}

export async function uploadPropertyBulk(payload) {
  const response = await api.post(`${ApiEndpoints.NEW_LIST_BULK}`, payload);

  return response;
}
export async function ViewPropertyById(payload) {
  const response = await api.get(`${ApiEndpoints.VIEW_PROPERTY}/${payload}`);

  return response;
}
export async function getPropertyResidentHistory(payload) {
  const response = await api.get(`${ApiEndpoints.PROPERTY_RESIDENT_HISTORY}/${payload}`);
  return response;
}
export async function getCommunityResidentId(payload) {
  const response = await api.get(`${ApiEndpoints.VIEW_RESIDENTID}/${payload}`);
  return response;
}
export async function addRestriction(payload) {
  console.log('coming from add restriction', payload);
  const response = await api.post(`${ApiEndpoints.ADD_RESTRICTION}`, payload);

  return response;
}

export async function addResidentDoc(payload) {
  const response = await api.post(`${ApiEndpoints.ADD_RESIDENTDOCUMENT}`, payload);

  return response;
}

export async function addNotification(payload) {
  const response = await api.post(`${ApiEndpoints.ADD_NOTIFICATION}`, payload);

  return response;
}

export async function getNotification(payload) {
  console.log(payload?.data.communityId);

  if (payload?.NoticeType) {
    const response = await api.get(
      `${ApiEndpoints.GET_NOTIFICATION}?NoticeType=${payload?.data.NoticeType}?COmmunityId=${
        payload?.data.communityId
      }&pageNumber=${payload?.data.pageIndex ?? 1}&pageSize=${payload.data.pageSize ?? 5}`,
    );
    return response;
  } else {
    const response = await api.get(
      `${ApiEndpoints.GET_NOTIFICATION}?COmmunityId=${payload?.data.communityId}&fromDate=${
        payload?.data.fromDate
      }&pageNumber=${payload?.data.pageIndex ?? 1}&pageSize=${payload?.data.pageSize ?? 5}`,
    );
    return response;
  }
}

export async function uploadResidentBulk(payload) {
  const response = await api.post(`${ApiEndpoints.ADD_RESIDENT}`, payload);

  return response;
}
export async function PropertyDropDown(payload) {
  const response = await api.get(`${ApiEndpoints.PROPERTY_DROPDOWN}/${payload?.commId}`);
  return response;
}
// export async function getSubRegions(payload) {
//   const response = await api.get(`${ApiEndpoints.COMMUNITY_PROPERTY}?CommunityId=${payload.CommunityId}`);

//   return response;
// }

export default {
  useGetCommunityResidentId: (...args) => useMutation(getCommunityResidentId, ...args),
  useGetAddResidentDoc: (...args) => useMutation(addResidentDoc, ...args),
};
