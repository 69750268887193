import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeProgress } from '../toploader/toploaderSlice';
import { ResetPassword } from '../../../hooks/api/userService';
import customToastComponent from '../../../utils/customToast';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const resetPasswordSlice = createSlice({
  name: 'resetPasswordSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetPasswordAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetPasswordAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(resetPasswordAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const resetPasswordAction = createAsyncThunk('resetPasswordAction', async ({ data, history }, thunkApi) => {
  thunkApi.dispatch(changeProgress(60));
  return ResetPassword(data)
    .then((res) => {
      thunkApi.dispatch(changeProgress(100));
      customToastComponent(res?.message);
      history.push('/app/login');
      return res;
    })
    .catch((err) => {
      thunkApi.dispatch(changeProgress(100));
      customToastComponent(err?.message, true);
      return thunkApi.rejectWithValue(err.message);
    });
});

export default resetPasswordSlice.reducer;
