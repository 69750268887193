import { useEffect, useState } from 'react';
import { fadeIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { toast } from 'react-toastify';
import BusinessProfile from './businessprofile';
import SettlementProfile from './settlement';
import partnerService from '../../shared/hooks/api/partnerService';
import CustomToast from '../../shared/components/customtoast';
import { Steps, Panel, ButtonGroup, Button } from 'rsuite';

const InfoAnimation = keyframes`${fadeIn}`;

const FadeDiv = styled.div`
  animation: 0.5s ${InfoAnimation};
`;

const ThePartnerForm = ({ location }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [bankAccount, setBankAccount] = useState([]);

  // const { mutateAsync: CreatePartner, isLoading } = partnerService.useCreatePartnerService();

  const [step, setStep] = useState(0);
  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  // proceed to the next step
  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  const setTheStep = (num) => {
    setStep(num);
  };

  const switchfunc = (st, nxt, prv) => {
    switch (st) {
      case 0:
        return (
          <FadeDiv>
            <BusinessProfile imageUrl={imageUrl} setImageUrl={setImageUrl} nextStep={nxt} />
          </FadeDiv>
        );
      case 1:
        return (
          <FadeDiv>
            <SettlementProfile
              // isLoading={isLoading}
              bankAccount={bankAccount}
              setBankAccount={setBankAccount}
              prevStep={prv}
            />
          </FadeDiv>
        );
      // case 3:
      //   return (
      //     <FadeDiv>
      //       <SettlementProfile />
      //     </FadeDiv>
      //   );
      default:
      // do nothing
    }
  };

  return (
    <div className="w-full bg-[#fdfdfd] py-9">
      <div className="mx-auto mt-2 w-[500px] bg-white">
        <div className="shadow rounded-md py-4">
          {/* <div className="flex justify-center items-center">
            <div className="relative mr-2 md:mr-4 my-26  ">
              <span className="font-semibold text-lg hover:text-appcolor-500">
                Business Profile
              </span>
              <div
                style={{ height: '2px' }}
                className={`${
                  step !== 0 && 'w-0'
                } absolute bg-black left-0 bottom-0 mt-10 transition-all duration-300 ${
                  step == 0 && 'w-full'
                }`}
              ></div>
            </div>
            <div className="relative ml-2 md:ml-4 my-26 ">
              <span className="font-semibold text-lg hover:text-appcolor-500">Settlement</span>
              <div
                style={{ height: '2px' }}
                className={`${
                  step !== 1 && 'w-0'
                } absolute bg-black left-0 bottom-0 mt-10 transition-all duration-300 ${
                  step == 1 && 'w-full'
                }`}
              ></div>
            </div>
          </div> */}

          {/* <Steps current={step}>
          <Steps.Item title="Business Profile" description="Become a Partner" color="yellow" />
          <Steps.Item title="Settlement" description="Add your bank Account" />
        </Steps> */}

          {switchfunc(step, nextStep, prevStep)}
        </div>
        <CustomToast />
      </div>
    </div>
  );

  //   {openSettingsForm === 'profile' && (
  //     <FadeDiv>
  //       <BusinessProfile />
  //     </FadeDiv>
  //   )}
  //   {openSettingsForm === 'preferences' && (
  //     <FadeDiv>
  //       <SettlementProfile />
  //     </FadeDiv>
  //   )}
  //   {openSettingsForm === 'account' && (
  //     <FadeDiv>
  //       <AccountProfile />
  //     </FadeDiv>
  //   )}
  // </div>
};

export default ThePartnerForm;
