import { InputField, Item, FormGroup, InputCheckBox } from './styles/ForrmInputs';

export const Input = ({ children, ...props }) => {
  return <Item {...props}>{children}</Item>;
};

Input.InputField = ({ children, otherclass, label, ...props }) => {
  return (
    <div className={`flex flex-col gap-1 items-start w-full my-2  py-1 rounded`}>
      <label className="text-sm  text-appcolor-900" htmlFor={props.name}>
        {label}
      </label>
      <InputField
        {...props}
        className="h-12 text-sm p-3 py-2 form-input w-full bg-transparent focus:outline-0 focus:ring-0 rounded placeholder:text-sm"
      >
        {children}
      </InputField>
    </div>
  );
};
// Input.InputField = ({ children, otherclass, ...props }) => {
//   return (
//     <>

//     <InputField
//       className={`form-input px-4 my-2  w-full rounded  focus:border-blue-600 placeholder:text-sm placeholder:text-slate-400 shadow ${otherclass}`}
//       {...props}
//     >
//       {children}
//     </InputField>
//     </>
//   );
// };

Input.FormGroup = ({ children, ...props }) => {
  return <FormGroup {...props}>{children}</FormGroup>;
};

Input.CheckBox = ({ children, className, ...props }) => {
  return (
    <InputCheckBox className={'form-checkbox mr-2 ml-1'} {...props}>
      {children}
    </InputCheckBox>
  );
};
