import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authOverlay, logo } from '../../../Entryfile/imagepath';
import { Input } from '../../../shared/components/formInputs';
import Button from '../../../shared/components/button';
import CustomToast, { CustomSuccessToast } from '../../../shared/components/customtoast';
import { useDispatch, useSelector } from 'react-redux';
import { MdMarkEmailUnread, MdOutlineClose } from 'react-icons/md';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { HiLockOpen } from 'react-icons/hi';
import AuthPageDesign from '../../../shared/components/misc/AuthPageDesign';
import { toast } from 'react-toastify';
import { RiLockPasswordFill } from 'react-icons/ri';
import { resetPasswordAction } from '../../../shared/store/slices/user/resetPassword';
import { FiEye } from 'react-icons/fi';

const getFormProps = () => {
  const initialValues = {
    code: '',
    newPassword: '',
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string().required('This field is required'),
    newPassword: Yup.string().required('Password is required'),
  });

  return {
    initialValues,
    validationSchema,
  };
};

const NewPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { loading } = useSelector((state) => state.resetPassword);
  const email = history.location.search.split('=')[1];
  console.log(email);

  const changeShowPassword = () => setShowPassword(!showPassword);

  if (!email) history.push('/reset-password');

  async function handleSubmit(values) {
    const { code, newPassword } = values;
    const payload = {
      passwordResetDto: {
        code,
        newPassword,
        username: email,
      },
    };

    dispatch(resetPasswordAction({ data: payload, history }));
  }
  
  return (
    <>
      <div className="flex md:flex-row flex-col w-full xl:h-screen 3xl:overflow-y-hidden">
        <AuthPageDesign action={'Login to your account'} />
        <div className="w-full px-3 md:px-0 md:w-5/12 h-screen flex flex-col relative justify-center items-center">
          <Link to="/" className="fixed text-appcolor-900 z-10 right-3 top-6 md:top-3 hover:border border-inset">
            <MdOutlineClose size={20} />
          </Link>
          <div
            className={`absolute  md:hidden inset-0 bg-no-repeat bg-cover`}
            style={{
              background: `url(${authOverlay})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              opacity: 0.4,
            }}
          ></div>

          <div className="w-full md:w-[90%] relative max-w-[500px] h-full flex flex-col justify-center items-center">
            <div className="absolute top-0 left-0 mt-4 md:hidden flex items-center">
              <img src={logo} alt="logo" className="w-36 h-10" />
            </div>

            <div
              className=" w-full rounded py-8 px-3 md:p-0 bg-white md:shadow-none md:bg-transparent"
              style={{ marginTop: '30px', marginBottom: '50px' }}
            >
              <div className="mt-10 md:mt-[unset] flex justify-between md:justify-start mb-5">
                <div className="px-4">
                  <p className="text-xl md:text-2xl md:mb-2 mb-1 font-bold"> New Password for Your Account</p>
                  <p className="text-sm text-appcolor-400 ">
                    Enter the OTP code sent to your email here. It only takes 5mins to expire.
                  </p>{' '}
                </div>
              </div>
              <div>
                <Formik
                  onSubmit={handleSubmit}
                  validateOnMount={true}
                  initialValues={getFormProps().initialValues}
                  validationSchema={getFormProps().validationSchema}
                >
                  {({ isSubmitting, isValid }) => (
                    <>
                      <div className="text-center">
                        <div className="">
                          <div className="flex justify-center"></div>
                          <div>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <Form style={{ width: '100%' }}>
                        <Input>
                          <div className="relative">
                            <Input.InputField
                              type="text"
                              name="code"
                              otherclass="py-2.5 !text-sm mb-2"
                              label={'OTP Code'}
                              placeholder=""
                            ></Input.InputField>
                            <ErrorMessage
                              className="-mt-2.5 text-xs text-left text-red-600"
                              component="div"
                              name="code"
                            />
                            <RiLockPasswordFill className="absolute right-3 top-11" size={20} color="#23395d" />
                          </div>
                          <div className="relative">
                            <Input.InputField
                              type={showPassword ? 'text' : 'password'}
                              name="newPassword"
                              otherclass="py-2.5 !text-sm mb-2"
                              label={'New Password'}
                              placeholder=""
                            ></Input.InputField>
                            <ErrorMessage
                              className="-mt-2.5 text-xs text-left text-red-600"
                              component="div"
                              name="newPassword"
                            />
                            <div role="button" className="absolute right-3 top-11">
                              {!showPassword ? (
                                <BsEyeSlash size={18} color="#111c2e" onClick={changeShowPassword} />
                              ) : (
                                <BsEye size={18} color="#111c2e" onClick={changeShowPassword} />
                              )}
                            </div>
                          </div>

                          <div>
                            <Button
                              className="flex"
                              type="submit"
                              disabled={isSubmitting || !isValid || loading}
                              isLoading={isSubmitting || loading}
                              style={{
                                width: '100%',
                                gap: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'centers',
                              }}
                            >
                              <HiLockOpen size={20} />
                              <span>Create New Password</span>
                            </Button>
                            <p className="text-center mt-3">
                              New to dweller?{' '}
                              <Link className="font-semibold text-appcolor-400 underline" to="/signup">
                                Sign up
                              </Link>{' '}
                            </p>
                          </div>
                        </Input>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomToast />
    </>
  );
};

export default NewPassword;
