import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ViewMaintenanceItems } from '../../../hooks/api/maintenanceService';

const initialState = {
  data: [],
  error: '',
  loading: false,
};

export const viewMaintenanceItemsSlice = createSlice({
  name: 'viewMaintenanceSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(viewMaintenanceItemsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(viewMaintenanceItemsAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(viewMaintenanceItemsAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const viewMaintenanceItemsAction = createAsyncThunk('viewMaintenanceItemsAction', async (data, apiThunk) => {
  return ViewMaintenanceItems(data)
    .then((res) => res)
    .catch((err) => apiThunk.rejectWithValue(err.message));
});

export default viewMaintenanceItemsSlice.reducer;
