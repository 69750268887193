import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetBanks } from '../../../hooks/api/bankService';
import { AddStaff } from '../../../hooks/api/partnerService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const partnerAddStaffSlice = createSlice({
  name: 'partnerAddStaffSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(partnerAddStaffAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(partnerAddStaffAction.fulfilled, (state, action) => {
      let theData = action.payload.map((val) => ({ label: `${val.name}`, value: val.code }));
      state.response = theData;
      state.loading = false;
    });
    builder.addCase(partnerAddStaffAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const partnerAddStaffAction = createAsyncThunk('partnerAddStaffAction', async (data, thunkApi) => {
  thunkApi.dispatch(changeProgress(60));

  return AddStaff(data)
    .then((res) => {
      thunkApi.dispatch(changeProgress(100));
      return res;
    })

    .catch((err) => {
      thunkApi.dispatch(changeProgress(100));
      return thunkApi.rejectWithValue(err.message);
    });
});

export default partnerAddStaffSlice.reducer;
