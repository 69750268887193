import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SubResident } from '../../../hooks/api/residenceService';

const initialState = {
  loading: false,
  response: [],
  error: '',
};

const subResidentSlice = createSlice({
  name: 'subResidentSlice',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(subResidentAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(subResidentAction.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
    });
    builder.addCase(subResidentAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const subResidentAction = createAsyncThunk('subResidentAction', async (data, thunkApi) => {
  return SubResident(data)
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default subResidentSlice.reducer;
