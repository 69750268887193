import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateCollection } from '../../../hooks/api/collectionService';
import { CreateCommunity } from '../../../hooks/api/communityService';
import { changeProgress } from '../toploader/toploaderSlice';
import { getPartnerCommunityAction } from './partnerCommunities';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const createCommunitySlice = createSlice({
  name: 'CreateCommunity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCommunityAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCommunityAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(createCommunityAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const createCommunityAction = createAsyncThunk(
  'createCommunity',
  async ({ data, close }, thunkApi, setClose) => {
    console.log('data from create community', data);
    thunkApi.dispatch(changeProgress(60));

    console.log(data, 'the create community data');
    return CreateCommunity(data)
      .then((res) => {
        thunkApi.dispatch(changeProgress(100));
        thunkApi.dispatch(
          getPartnerCommunityAction({
            data: { fromDate: new Date(2021, 11, 31).toISOString(), communityType: 1 },
          }),
        );
        close(false);
        return res;
      })
      .catch((err) => {
        close(false);
        thunkApi.dispatch(changeProgress(100));

        return thunkApi.rejectWithValue(err);
      });
  },
);

export default createCommunitySlice.reducer;
