import { useEffect, useState } from 'react';
import { Circles } from 'react-loader-spinner';
import { GoTrashcan } from 'react-icons/go';
import { toast } from 'react-toastify';
import { Button2 } from '../../../shared/components/button';
import InputField from '../../../shared/components/customforminput';
import CustomSelect from '../../../shared/components/selectinput';
import bankService from '../../../shared/hooks/api/bankService';
import CustomToast from '../../../shared/components/customtoast';
import { useDispatch, useSelector } from 'react-redux';
import { getBanksAction } from '../../../shared/store/slices/partner/getBankSlice';
import { verifyBanksAction } from '../../../shared/store/slices/partner/verifyBankSlice';

const BankAccounts = ({ setFieldValue, bankAccount, setBankAccount }) => {
  const dispatch = useDispatch();
  const { loading, response: defaultOptions } = useSelector((state) => state.getBanks);
  const { loading: isLoading, response: verifyRes } = useSelector((state) => state.verifyBanks);
  const { mutateAsync: VerifyBank, isLoading: verifyLoading } = bankService.useVerifyBankService();
  const [accountDetails, setAccountDetails] = useState({
    bankcode: '',
    account_number: '',
    bankname: '',
  });
  useEffect(() => {
    dispatch(getBanksAction());
  }, []);

  const loadOptions = async (text, callback) => {
    // // // const res = await axios.get(`${BASE_URL}${ApiEndpoints.SPECIALIZATION}?search=${text}`);
    // const res = await GetBanks();

    callback(defaultOptions);
  };

  const onCustomSelectChange = (i) => {
    setAccountDetails({ ...accountDetails, bankcode: i.value, bankname: i.label });
  };

  const onAccountChange = (i) => {
    i.persist();
    setAccountDetails((current) => ({ ...current, account_number: i.target.value }));
  };
  let newbankacount = [];

  const addBank = async () => {
    dispatch(
      verifyBanksAction({
        account_number: accountDetails.account_number,
        bankcode: accountDetails.bankcode,
      }),
    ).then((verifyRes) => {
      console.log(verifyRes);
      if (verifyRes.meta.requestStatus === 'fulfilled') {
        newbankacount.push({
          bankCode: verifyRes.bankCode,
          bankAccount: verifyRes.accountNumber,
          accountName: verifyRes.accountName,
          isPrimary: true,
        });
        setBankAccount((current) =>
          current
            ? [
                ...current,
                {
                  bankCode: verifyRes.payload.bankCode,
                  bankAccount: verifyRes.payload.accountNumber,
                  accountName: verifyRes.payload.accountName,

                  isPrimary: true,
                },
              ]
            : [
                {
                  bankCode: verifyRes.payload.bankCode,
                  bankAccount: verifyRes.payload.accountNumber,
                  accountName: verifyRes.payload.accountName,
                  isPrimary: true,
                },
              ],
        );
        setFieldValue('partnerBankAccounts', newbankacount);
      }
      setAccountDetails({
        bankcode: '',
        account_number: '',
        bankname: '',
      });
    });
  };

  const removeAccount = (acc) => {
    let arr = [...bankAccount];
    arr.splice(
      arr.findIndex((i) => i.accountNumber === acc.accountNumber),
      1,
    );

    setBankAccount([...arr]);
    const thedetails = newbankacount.filter((i) => i.accountNumber !== acc.accountNumber);
    setFieldValue('partnerBankAccounts', newbankacount);
  };

  return (
    <>
      <div className="relative ">
        <div
          className={`w-full absolute h-full ${!verifyLoading && 'hidden'} justify-center items-center ${
            verifyLoading && 'flex'
          }`}
          style={{ backgroundColor: 'rgba(156,163,175, 0.4)', zIndex: 3 }}
        >
          <Circles color="#808080" width={50} height={50} />
        </div>
        <div className="my-1 pr-1">
          <CustomSelect
            loadOptions={loadOptions}
            onChangeFunction={onCustomSelectChange}
            placeholder="Search For Banks"
            defaultOptions={defaultOptions}
            value={{ label: accountDetails.bankname, value: accountDetails.bankcode }}
          />
        </div>
        <div className="mt-1 flex flex-col items-center ">
          <div className="w-full mr-1">
            <InputField
              inputType="input"
              type="text"
              name="account_number"
              value={accountDetails.account_number}
              label={'Account Number'}
              placeholder=""
              onChange={onAccountChange}
            />
          </div>
          <div className="w-full ml-1">
            <Button2 type="button" classes={'w-full !bg-slate-200 hover:!bg-gray-600'} onClick={addBank}>
              Verify Bank
            </Button2>
          </div>
        </div>
        <div className="mt-5 w-full flex" style={{ overflowX: 'scroll' }}>
          {bankAccount?.length > 0 &&
            bankAccount?.map((account) => {
              return (
                <div
                  key={account.bankName}
                  className="w-52 mr-3 h-28 bg-appcolor-900 flex relative justify-center items-center text-white rounded-md shadow-md"
                >
                  <div
                    onClick={() => removeAccount(account)}
                    className="absolute cursor-pointer"
                    style={{ right: '-15px', top: '-1px', zIndex: 2 }}
                  >
                    <GoTrashcan className="text-red-900" />
                  </div>
                  <div className="absolute top-0.5 right-1.5">
                    <span className="text-xs">{account.bankName}</span>
                  </div>
                  <div className="">
                    <span className="text-sm font-bold">{account.bankAccount}</span>
                  </div>
                  <div className="absolute bottom-0.5 left-1.5">
                    <span className="text-xs" style={{ fontSize: '10px' }}>
                      {account.accountName}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
        <CustomToast />
      </div>
    </>
  );
};

export default BankAccounts;
