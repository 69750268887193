import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UpdateWorkorderItems, ViewMaintenanceItems } from '../../../hooks/api/maintenanceService';
import customToastComponent from '../../../utils/customToast';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const updateWorkorderItemSlice = createSlice({
  name: 'updateWorkorderItemSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateWorkorderItemsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateWorkorderItemsAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(updateWorkorderItemsAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const updateWorkorderItemsAction = createAsyncThunk('updateWorkorderItemsAction', async (data, thunkApi) => {
  return UpdateWorkorderItems(data)
    .then((res) => {
      customToastComponent(res?.data?.message || 'updated successfull');
      return res;
    })
    .catch((err) => {
      customToastComponent(err.message, true);
      thunkApi.rejectWithValue(err.message);
    });
});

export default updateWorkorderItemSlice.reducer;
