import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PartnerSignIn } from '../../../hooks/api/partnerService';
import { SignInUser } from '../../../hooks/api/userService';
import { dApis } from '../../../utils/api';
import { GET_STORAGE_ITEM, SET_STORAGE_ITEM } from '../../../utils/storage';
import { changeProgress } from '../toploader/toploaderSlice';
import { changeLoading } from './loginSlice';

const initialState = {
  loading: false,
  error: null,
  token: GET_STORAGE_ITEM('token') || null,
  partnerObject: GET_STORAGE_ITEM('partner') || null,
  currentUser: GET_STORAGE_ITEM('currentUser') || null,
};

const partnerSlice = createSlice({
  name: 'partnerSignIn',
  initialState,
  reducers: {
    logoutUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(partnerSignInAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(partnerSignInAction.fulfilled, (state, action) => {
      state.partnerObject = {
        id: action.payload.partner.id,
        logo: action.payload.partner.companyLogo,
        name: action.payload.partner.name,
        type: action.payload.partner.partnerType.label,
        roleDefination: action.payload.roleDefination,
      };
      state.currentUser = {
        name: action.payload.user.firstName + ' ' + action.payload.user.lastName,
        id: action.payload.user.id,
        email: action.payload.user.email,
        permissions: action.payload.permissions.map((item) => item.permission),
        role: action.payload.role.value,
      };
      state.loading = false;
    });
    builder.addCase(partnerSignInAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const partnerSignInAction = createAsyncThunk('partnerSignIn', async ({ data, history }, thunkApi) => {
  thunkApi.dispatch(changeProgress(38));
  thunkApi.dispatch(changeLoading(true));
  return PartnerSignIn(data)
    .then((res) => {
      if (res.isSuccess === true && res.partner === null) {
        thunkApi.dispatch(changeProgress(100));
        thunkApi.dispatch(changeLoading(false));
        SET_STORAGE_ITEM('currentUser', {
          name: res.user.firstName + ' ' + res.user.lastName,
          id: res.user.id,
          email: res.user.email,
        });

        history.push('/create-partner');
        return;
      } else if (res.isSuccess === true && res.partner) {
        SET_STORAGE_ITEM('partner', {
          logo: res.partner.companyLogo,
          regularId: res.id,
          hasAccount: res.partner.hasAccount,
          id: res.partner.id,
          name: res.partner.name,
          type: res.partner.partnerType.label,
          roleDefination: res.roleDefination,
          isAutomaticDisbursement:res.partner.isAutomaticDisbursement
        });
        SET_STORAGE_ITEM('currentUser', {
          name: res.user.firstName + ' ' + res.user.lastName,
          id: res.user.id,
          email: res.user.email,
          permissions: res.permissions.map((item) => item.permission),
          role: res.role.value,
        });
        SET_STORAGE_ITEM('token', res.token);
        dApis.defaults.headers['Authorization'] = `Bearer ${res.token}`;

        thunkApi.dispatch(changeProgress(100));
        thunkApi.dispatch(changeLoading(false));
        history.push('/app/dashboard');
        return res;
      }
    })
    .catch((err) => {
      thunkApi.dispatch(changeProgress(100));
      thunkApi.dispatch(changeLoading(false));
      if (err?.status?.value === 5) {
        history.push('/unverified');
        return thunkApi.rejectWithValue(err.message);
      } else if (err?.status === null) {
        if (err.message === 'Please Contact Dweller Support') {
          history.push('/create-partner');
          return thunkApi.rejectWithValue('PLease Create Your Partner Account');
        } else {
          return thunkApi.rejectWithValue(err.message);
        }
      } else if (err?.status.value === 6) {
        history.push({
          pathname: '/otp',
          state: { email: err?.message?.split('|')[0], phone: err?.message?.split('|')[1] },
        });
      }

      //return thunkApi.rejectWithValue(err.message);
    });
});

export default partnerSlice.reducer;

export const { logoutUser } = partnerSlice.actions;
