import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getStates } from '../../../hooks/api/stateService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const getStatesSlice = createSlice({
  name: 'getstates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatesAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStatesAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(getStatesAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const getStatesAction = createAsyncThunk('getStates', async (data, thunkApi) => {
  return getStates()
    .then((res) => {
      return res;
    })

    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default getStatesSlice.reducer;
