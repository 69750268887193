import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UpdateCommunity } from '../../../hooks/api/communityService';
import { communityDetailsAction } from './communityDetails';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const UpdateCommunitySlice = createSlice({
  name: 'communitydetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateCommunityAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCommunityAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(updateCommunityAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const updateCommunityAction = createAsyncThunk('updateCommunity', async ({ data, id }, thunkApi) => {
  // thunkApi.dispatch(changeProgress(60));
  return UpdateCommunity({ id, payload: data })
    .then((res) => {
      // thunkApi.dispatch(changeProgress(100));
      if (res) {
        thunkApi.dispatch(communityDetailsAction({ data: id }));
        return res;
      }
    })
    .catch((err) => {
      // thunkApi.dispatch(changeProgress(100));
      console.log(err);
      return thunkApi.rejectWithValue(err);
    });
});

export default UpdateCommunitySlice.reducer;
