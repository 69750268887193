import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { StartWorkorder, ViewMaintenance } from '../../../hooks/api/maintenanceService';
import customToastComponent from '../../../utils/customToast';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const startWorkorderSlice = createSlice({
  name: 'startWorkorderSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(startWorkorderAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(startWorkorderAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(startWorkorderAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const startWorkorderAction = createAsyncThunk('startWorkorderAction', async (data, thunkApi) => {
  return StartWorkorder({ workOrderId: data?.workOrderId })
    .then((res) => {
      customToastComponent('Workorder started successfully');
      ViewMaintenance(data);
      return res;
    })
    .catch((err) => {
      customToastComponent(err.message, true);
      thunkApi.rejectWithValue(err.message);
    });
});

export default startWorkorderSlice.reducer;
