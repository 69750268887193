import api from '../../utils/api';
import { useMutation } from 'react-query';
import { ApiEndpoints } from '../../config/Endpoints';
import axios from 'axios';

const today = new Date();
//using tomorrow's date, so we don't get back stale data on query invalidation
let tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);
tomorrow = new Date(tomorrow).toISOString();

async function GetAllAssets(payload) {
  const response = await api.get(
    `${ApiEndpoints.ALL_ASSETS}?PageNumber=${payload?.pageIndex}&StartDate=${payload?.startDate || null}&ToDate=${
      payload?.toDate || tomorrow
    }&Status=${payload?.status || null}`,
  );
  return response;
}
async function GetAllAssetTypes(payload) {
  const response = await api.get(
    `${ApiEndpoints.ALL_ASSET_TYPE}?PageNumber=${payload?.pageIndex}&ToDate=${tomorrow}&PageSize=10&PageIndex=${
      payload?.pageIndex
    }name=${payload?.name || ''}`,
  );
  return response;
}

async function GetAllAssetActivities(payload) {
  const response = await api.get(
    `${ApiEndpoints.ALL_ASSET_ACTIVITIES}?PageNumber=${payload?.pageIndex || 1}&StartDate=${
      payload?.startDate || null
    }&ToDate=${payload?.toDate || tomorrow}&AssetId=${payload?.id}`,
  );
  return response;
}

async function GetAssetReporting() {
  const response = await api.get(ApiEndpoints.REPORT_ALL_ASSET);
  return response;
}

async function GetAssetReportingById(id) {
  const response = await api.get(`${ApiEndpoints.REPORT_ASSETBYID}?assetId=${id}`);
  return response;
}

async function ViewAsset(id) {
  const response = await api.get(`${ApiEndpoints.VIEW_ASSET}/${id}`);
  return response;
}

async function CreateAsset(payload) {
  const response = await api.post(`${ApiEndpoints.CREATE_ASSET}`, payload);
  return response;
}

async function CreateAssetType(payload) {
  const response = await api.post(`${ApiEndpoints.CREATE_ASSET_TYPE}`, payload);
  return response;
}

async function UpdateAssetStatus(payload) {
  const response = await api.post(`${ApiEndpoints.UPDATE_ASSET_STATUS}`, payload);
  return response;
}

export async function UploadImage(payload) {
  const response = await axios.post('https://storage.googleapis.com/dweller-bucket/', payload);
  return response;
}

export async function AssetDropdown(payload) {
  const response = api.get(`${ApiEndpoints.ALL_ASSET_DROPDOWN}?ToDate=${tomorrow}&Name=${payload?.Name}`);
  return response;
}

export default {
  useGetAllAssets: (...args) => useMutation(GetAllAssets, ...args),
  useGetAllAssetTypes: (...args) => useMutation(GetAllAssetTypes, ...args),
  useCreateAsset: (...args) => useMutation(CreateAsset, ...args),
  useCreateAssetType: (...args) => useMutation(CreateAssetType, ...args),
  useAssetImageUploadService: (...args) => useMutation(UploadImage, ...args),
  useUpdateAssetStatus: (...args) => useMutation(UpdateAssetStatus, ...args),
  useViewAsset: (...args) => useMutation(ViewAsset, ...args),
  useGetAssetActivities: (...args) => useMutation(GetAllAssetActivities, ...args),
  useGetAssetReports: (...args) => useMutation(GetAssetReporting, ...args),
  useGetAssetReportsById: (...args) => useMutation(GetAssetReportingById, ...args),
};
