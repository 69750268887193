import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PartnerBonus } from '../../../hooks/api/partnerService';

const initialState = {
  loading: false,
  response: [],
  error: '',
};

const partnerBonusSlice = createSlice({
  name: 'partnerBonusSlice',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(partnerBonusAction.pending, (state, action) => {
        state.loading = true
    } );
    builder.addCase(partnerBonusAction.fulfilled, (state,action) => {
        state.loading = false;
        state.response = action.payload;
    })
    builder.addCase(partnerBonusAction.rejected, (state,action) => {
        state.loading = false;
        state.error = action.payload;
    })
  },


});

export const partnerBonusAction = createAsyncThunk('partnerBonusAction', async ( apiThunk) => {
  return PartnerBonus()
    .then((res) => res)
    .catch((err) => apiThunk.rejectWithValue(err.message));
});

export default partnerBonusSlice.reducer;
