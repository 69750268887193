import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CollectionReport } from '../../../hooks/api/reportService';
import { getRegions } from '../../../hooks/api/stateService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: true,
  error: null,
  response: null,
};

const getCollectionReportSlice = createSlice({
  name: 'getcollectionSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCollectionReportAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollectionReportAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(getCollectionReportAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const getCollectionReportAction = createAsyncThunk('getcollectionaction', async (data, thunkApi) => {
  return CollectionReport(data)
    .then((res) => {
      return res;
    })

    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default getCollectionReportSlice.reducer;
