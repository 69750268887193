import moment from 'moment';
import React, { useState } from 'react';
const FilterContext = React.createContext({});

const initialState = {};

const FilterContextProvider = ({ children, ...props }) => {
  const [filterby, setFilterby] = useState({ type: 'date', value: moment().year() });

  const value = { filterby, setFilterby };

  return (
    <FilterContext.Provider value={value} {...props}>
      {children}
    </FilterContext.Provider>
  );
};

function useFilterState() {
  const context = React.useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilterState must be used within a FilterProvider');
  }

  return context;
}

export { FilterContextProvider, useFilterState };
