import api from '../../utils/api';
import axios from 'axios';
import { useMutation } from 'react-query';
import { ApiEndpoints } from '../../config/Endpoints';
import moment from 'moment';

const currentYear = new Date().toISOString();

export async function CollectionReport(payload) {
  const response = await api.get(
    `${ApiEndpoints.REPORT_COLLECTION}?StaffId=${payload?.staffId ?? ''}&Year=${payload?.year ?? moment().year()}`,
  );

  return response;
}

export async function CommunityAndManagementReport() {
  const response = await api.get(`${ApiEndpoints.REPORT_COMMUNITYANDMANAGEMENT}`);

  return response;
}

export async function PropertyReport(payload) {
  const response = await api.get(`${ApiEndpoints.REPORT_PROPERTY}?staffId=${payload?.staffId ?? ''}`);

  return response;
}

export async function ActivityLog(payload) {
  const response = await api.get(
    `${ApiEndpoints.ALL_PARTNER_LOG}?FromDate=${payload?.fromDate || null}&ToDate=${currentYear}&PageNumber=${
      payload?.pageNumber || 1
    }`,
  );
  return response;
}

export async function ResidentActivityLog(payload) {
  console.log('reportservice payload', payload);
  const response = await api.get(
    `${ApiEndpoints.RESIDENT_PARTNER_LOG}?FromDate=${
      payload?.fromDate || null
    }&ToDate=${currentYear}&ResidentId=${payload}&PageNumber=${payload?.pageNumber || 1}`,
  );
  return response;
}

export async function ReportAdminWorkorder() {
  const response = await api.get(`${ApiEndpoints.REPORT_ADMIN_WORKORDER}`);
  return response;
}

export async function ReportStaffWorkorder() {
  const response = await api.get(`${ApiEndpoints.REPORT_STAFF_WORKORDER}`);
  return response;
}

export async function ReportStaffComm() {
  const response = await api.get(`${ApiEndpoints.REPORT_STAFF_COMMUNITY}`);
  return response;
}

export default {
  useGetCollectionReportService: (...args) => useMutation(CollectionReport, ...args),
  useGetCommunityAndManagementReportService: (...args) => useMutation(CommunityAndManagementReport, ...args),
  useGetPropertyReportService: (...args) => useMutation(PropertyReport, ...args),
  useGetActivityLogService: (...args) => useMutation(ActivityLog, ...args),
};
