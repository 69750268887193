const { AiOutlineCheck } = require('react-icons/ai');

const Timeline = ({ item, idx, totalCount }) => (
  <div className={`${idx + 1 !== totalCount && 'pb-4'} flex flex-col border-l border-l-green-500 pl-7 relative`}>
    <div className="absolute rounded-full bg-green-500 z-10 top-2 -left-[.65rem] grid place-content-center w-5 h-5 border-green-500">
      <AiOutlineCheck size={14} />
    </div>
    <p className="text-2xl font-medium">{item.title}</p>
    <p className="text-sm mt-1 opacity-75"> {item.body}</p>
  </div>
);

export default Timeline;
