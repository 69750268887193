import React, { useRef, Suspense, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Loading, RouteGuard } from './RouteGuard';
import { routes } from './Routes';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import CustomToast from '../shared/components/customtoast';
import { useDispatch, useSelector } from 'react-redux';
import { changeProgress } from '../shared/store/slices/toploader/toploaderSlice';

const MainApp = (props) => {
  const dispatch = useDispatch();
  const { progress } = useSelector((state) => state.topLoader);
  return (
    <Suspense fallback={<Loading />}>
      <>
        <LoadingBar
          color="#152238"
          progress={progress}
          loaderSpeed={2500}
          onLoaderFinished={() => dispatch(changeProgress(0))}
        />
        <Switch>
          {routes.map((route, i) => (route.guarded ? <RouteGuard key={i} {...route} /> : <Route key={i} {...route} />))}
          {/* <Redirect
          to={{
            pathname: '/',
            state: {
              from: '/',
            },
          }}
        ></Redirect> */}
        </Switch>
        <CustomToast />
      </>
    </Suspense>
  );
};
export default MainApp;
