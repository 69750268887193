import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { uploadPropertyBulk } from '../../../hooks/api/propertyService';
import { changeProgress } from '../toploader/toploaderSlice';
import { getCommunityPropertyAction } from './communityPropertySlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const uploadBulkPropertySlice = createSlice({
  name: 'uploadBulkPropertySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadPropertyBulkAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadPropertyBulkAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(uploadPropertyBulkAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const uploadPropertyBulkAction = createAsyncThunk('uploadBulkPropertyAction', async ({ data, id }, thunkApi) => {
  thunkApi.dispatch(changeProgress(60));

  console.log(data);
  return uploadPropertyBulk(data)
    .then((res) => {
      thunkApi.dispatch(changeProgress(100));
      setTimeout(() => {
        thunkApi.dispatch(getCommunityPropertyAction({ communityId: id }));
      }, 1500);
      return res;
    })
    .catch((err) => {
      thunkApi.dispatch(changeProgress(100));

      return thunkApi.rejectWithValue(err.message);
    });
});

export default uploadBulkPropertySlice.reducer;
