import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UploadImage } from '../../../hooks/api/partnerService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  response: null,
  error: null,
};

export const imageUploadAsyncAction = createAsyncThunk('imageupload', (data, thunkApi) => {
  thunkApi.dispatch(changeProgress(60));
  return UploadImage(data)
    .then((res) => {
      thunkApi.dispatch(changeProgress(100));
      return res;
    })
    .catch((err) => {
      thunkApi.dispatch(changeProgress(100));
      return thunkApi.rejectWithValue(err);
    });
});

const imageUploadSlice = createSlice({
  name: 'imageUpload',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(imageUploadAsyncAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(imageUploadAsyncAction.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
    });
    builder.addCase(imageUploadAsyncAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default imageUploadSlice.reducer;
