import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { uploadResidentBulk } from '../../../hooks/api/propertyService';
import { changeProgress } from '../toploader/toploaderSlice';
import { getCommunityResidentAction } from './communityResidentSlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const uploadBulkResidentSlice = createSlice({
  name: 'uploadBulkResidentSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadResidentBulkAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadResidentBulkAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(uploadResidentBulkAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const uploadResidentBulkAction = createAsyncThunk('uploadBulkResidentAction', async ({ data, id }, thunkApi) => {
  thunkApi.dispatch(changeProgress(60));

  return uploadResidentBulk(data)
    .then((res) => {
      thunkApi.dispatch(changeProgress(100));
      setTimeout(() => {
        thunkApi.dispatch(getCommunityResidentAction({ communityId: id }));
      }, 2000);
      return res;
    })
    .catch((err) => {
      thunkApi.dispatch(changeProgress(100));

      return thunkApi.rejectWithValue(err.message);
    });
});

export default uploadBulkResidentSlice.reducer;
