import api from '../../utils/api';
import { ApiEndpoints } from '../../config/Endpoints';

const today = new Date();
//using tomorrow's date, so we don't get back stale data on query invalidation
let tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);
tomorrow = new Date(tomorrow).toISOString();

export async function ResidentDropdown(payload) {
  const response = api.get(`${ApiEndpoints.RESIDENT_DROPDOWN}/${payload?.commId}`);
  return response;
}

export async function getResidentTable(payload) {
  const response = await api.get(`${ApiEndpoints.GET_RESIDENT_TABLE}/${payload}`);
  return response;
}

export async function RemoveRestriction(payload) {
  const response = await api.post(`${ApiEndpoints.REMOVE_RESTRICTION}/${payload?.id}`);
  return response;
}

export async function SubResident(payload) {
  const response = await api.get(`${ApiEndpoints.SUB_RESIDENT}/${payload?.id}`);
  return response;
}
