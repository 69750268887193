import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { ViewPropertyById } from '../../../hooks/api/propertyService';

const initialState = {
  loading: false,
  error: null,
  fromDate: null,
  toDate: null,
  response: null,
};

const viewPropertySlice = createSlice({
  name: 'viewProperty',
  initialState,
  reducers: {
    changeDate: (state, action) => {
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
    defaultState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(viewPropertyAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewPropertyAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(viewPropertyAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const viewPropertyAction = createAsyncThunk('viewProperty', async (data, thunkApi) => {
  return ViewPropertyById(data)
    .then((res) => {
      return res;
    })

    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default viewPropertySlice.reducer;
