import { useRef, useState } from 'react';
import { Field } from 'formik';
import { Circles } from 'react-loader-spinner';
import partnerService from '../../../shared/hooks/api/partnerService';
import { RiFileUploadFill, RiUploadCloud2Fill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { imageUploadAsyncAction } from '../../../shared/store/slices/image/imageUploadSlice';
import { FiEdit } from 'react-icons/fi';
import { changeImageAction } from '../../../shared/store/slices/user/changeImageSlice';
import { ImSpinner2 } from 'react-icons/im';
import { userDetailAction } from '../../../shared/store/slices/user/getUser';
import { staffDetailsAction } from '../../../shared/store/slices/partner/staffDetailSlice';
import { TbFileUpload } from 'react-icons/tb';

const ImageComponent = ({ onChangeFunction, ...props }) => {
  const [imageUrl, setImageUrl] = useState();
  const [fileName, setFileName] = useState('Upload Company Logo');
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.imageUpload);
  const { loading: changeImageLoading } = useSelector((state) => state.changeImage);
  const imgPickerRef = useRef(null);
  const partner = JSON.parse(window.localStorage.getItem('partner'));
  const regularId = partner?.regularId;

  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );
  }

  const handleFileChange = async (e) => {
    let image = e.target.files[0];

    let blob = image.slice(0, image.size);
    let newFile = new File([blob], `${uuidv4()}_logo.jpg`, { type: `${image.type}` });

    let formData = new FormData();
    formData.append('image', newFile);
    setFileName(e.target.files[0].name);
    dispatch(imageUploadAsyncAction(formData)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setImageUrl(res.payload.imageUrl);
        if (onChangeFunction) onChangeFunction(res?.payload?.imageUrl);
        if (props.addDocument) {
          props.formik.setFieldValue(props.name, res?.payload?.imageUrl);
        }
      }
    });
  };

  function handleUpdatePhoto() {
    const payload = { imageURL: imageUrl };
    dispatch(changeImageAction(payload)).then(() => dispatch(userDetailAction()));
    dispatch(staffDetailsAction({ id: regularId }));
  }

  return (
    <>
      {props.userProfile ? (
        <div className="flex align-items-center justify-center flex-col gap-3">
          <button
            type="button"
            onClick={() => imgPickerRef.current.click()}
            title="change photo"
            className="w-fit flex items-center gap-5 relative"
          >
            <FiEdit className="absolute bottom-0 -right-1" />
            <img src={imageUrl ? imageUrl : props.user?.photo} alt="user" className="w-28 h-32 rounded-md" />
            <input type={'file'} hidden ref={imgPickerRef} accept="image/*" onChange={handleFileChange} />
          </button>
          {imageUrl ? (
            <button
              type="button"
              disabled={changeImageLoading}
              onClick={handleUpdatePhoto}
              className=" disabled:opacity-60 flex justify-center bg-appcolor-600 text-sm text-white rounded min-w-24 max-w-[115px] px-3 py-1"
            >
              {changeImageLoading ? <ImSpinner2 className="animate-spin my-1.5" /> : 'save photo'}
            </button>
          ) : null}
        </div>
      ) : props.addDocument ? (
        <div
          onClick={() => imgPickerRef.current.click()}
          role="button"
          className="rounded border-dashed text-center gap-1 border-black border-2 py-10 flex-col justify-center items-center"
        >
          <div>
            <TbFileUpload color="darkblue" size={30} className="mx-auto text" />
          </div>
          <p className="text-sm">Browse image to upload (.png, .jpg)</p>
          <input type={'file'} hidden ref={imgPickerRef} accept="image/*" onChange={handleFileChange} />
          {fileName && fileName !== 'Upload Company Logo' && <p className="text-sm italic">{`{{ ${fileName} }}`}</p>}
        </div>
      ) : (
        <div
          role="button"
          className="flex relative justify-between items-center px-3 py-3 mb-7 mt-5 rounded border border-appcolor-900"
        >
          <Field
            type="file"
            name="file"
            className="absolute inset-0 w-full opacity-0 cursor-pointer"
            onChange={handleFileChange}
          />
          <span className="text-sm font-medium">{fileName}</span>
          {loading ? <Circles color="#808080" width={20} height={20} /> : <RiUploadCloud2Fill size={20} />}
        </div>
      )}
    </>
  );
};

export default ImageComponent;
