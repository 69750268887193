import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DisbursementRequests } from '../../../hooks/api/financeService';

const initialState = {
  loading: true,
  error: null,
  response: null,
};

const disbursementRequests = createSlice({
  name: 'disbursementRequests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(disbursementRequestsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(disbursementRequestsAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(disbursementRequestsAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const disbursementRequestsAction = createAsyncThunk(
  'disbursementRequestsAction',
  async (data, thunkApi) => {
    return DisbursementRequests()
      .then((res) => {
        return res;
      })

      .catch((err) => {
        return thunkApi.rejectWithValue(err.message);
      });
  },
);

export default disbursementRequests.reducer;
