import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import Button from '../../../shared/components/button';
import BankAccounts from './bankaccounts';
import partnerService from '../../../shared/hooks/api/partnerService';
import { useDispatch, useSelector } from 'react-redux';
import { addPartnerBanksAction } from '../../../shared/store/slices/partner/addPartnerBankSlice';

let schema = yup.object().shape({
  partnerBankAccounts: yup.array().min(1).max(1).required(),
});
const SettlementProfile = ({ prevStep }) => {
  const [bankAccount, setBankAccount] = useState();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.addPartnerBank);

  const history = useHistory();

  const initialValues = {
    partnerBankAccounts: '',
  };
  const onSubmit = (val) => {
    dispatch(addPartnerBanksAction({ data: { partnerBankAccounts: bankAccount }, history }));
  };
  return (
    <Formik onSubmit={onSubmit} validateOnMount initialValues={initialValues} validationSchema={schema}>
      {({ isValid, isSubmitting, getFieldProps, setFieldValue }) => {
        return (
          <Form>
            <>
              <div className="w-full mt-10 text-center">
                <h3 className="text-xl font-bold">Business Bank Accounts</h3>
              </div>
              <div className="px-8 py-8 bg-white my-8  ">
                <BankAccounts setFieldValue={setFieldValue} bankAccount={bankAccount} setBankAccount={setBankAccount} />
                <div className="grid grid-cols-4 gap-4 w-full mt-8">
                  <div className="mx-1 col-span-2">
                    <Button
                      // type="submit"
                      // isLoading={isLoading}
                      // disabled={isSubmitting || !isValid}
                      aClassName={'w-full '}
                      onClick={() => history.push('/app/dashboard')}
                    >
                      Skip
                    </Button>
                  </div>
                  <div className="mx-1 col-span-2">
                    <Button type="submit" isLoading={loading} disabled={loading || !isValid} aClassName={'w-full mx-1'}>
                      Add Account
                    </Button>
                  </div>
                </div>
              </div>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SettlementProfile;
