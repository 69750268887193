import api from '../../utils/api';
import { ApiEndpoints, BASE_URL } from '../../config/Endpoints';

const today = new Date();
//using tomorrow's date, so we don't get back stale data on query invalidation
let tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);
tomorrow = new Date(tomorrow).toISOString();

export async function GetAllServiceRequestTypes(payload) {
  const response = await api.get(
    `${ApiEndpoints.ALL_SERVICE_REQUEST_TYPE}?ToDate=${tomorrow}&Name=${payload?.Name ?? ''}`,
  );
  return response;
}

export async function CreateServiceRequestType(payload) {
  const response = await api.post(`${ApiEndpoints.CREATE_SERVICE_REQUEST_TYPE}`, payload);
  return response;
}

export async function ViewMaintenance(payload) {
  const response = await api.get(`${ApiEndpoints.VIEW_MAINTENANCE}/${payload?.id}`);
  return response;
}

export async function GetAllMaintenance(payload) {
  const response = api.get(
    `${ApiEndpoints.ALL_MAINTENANCE}?WorkOrderType=${payload?.WorkOrderType ?? 1}&ToDate=${tomorrow}&PageNumber=${
      payload?.pageIndex ?? 1
    }`,
  );
  return response;
}

export async function MyJobs(payload) {
  const response = api.get(`${ApiEndpoints.MY_JOBS}?WorkOrderType=${payload?.workorderType}&ToDate=${tomorrow}`);
  return response;
}

export async function GetAllMaintenanceSummary() {
  const response = api.get(`${ApiEndpoints.ALL_MAINTENANCE_SUMMARY}`);
  return response;
}

export async function GetMyMaintenanceSummary() {
  const response = api.get(`${ApiEndpoints.MY_MAINTENANCE_SUMMARY}`);
  return response;
}

export async function GetAllServiceRequestSummary() {
  const response = api.get(`${ApiEndpoints.ALL_SERVICE_REQUEST_SUMMARY}`);
  return response;
}

export async function GetMyServiceRequestSummary() {
  const response = api.get(`${ApiEndpoints.MY_SERVICE_REQUEST_SUMMARY}`);
  return response;
}

export async function GetWorkOrderActivities(payload) {
  const response = await api.get(`${ApiEndpoints.WORKORDER_ACTIVITY}/${payload?.id}?ToDate=${tomorrow}`);
  return response;
}

export async function CreateMaintenance(payload) {
  const response = await api.post(`${ApiEndpoints.CREATE_MAINTENANCE}`, payload, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
  return response;
}

export async function ViewWorkOrderStaff(payload) {
  const response = await api.get(`${ApiEndpoints.WORKORDER_STAFF}/${payload?.workId}`);
  return response;
}

export async function ViewMaintenanceItems(payload) {
  const response = await api.get(`${ApiEndpoints.MAINTENANCE_ITEMS}/${payload?.maintenanceId}`);
  return response;
}

export async function UpdateWorkorderItems(payload) {
  const response = await api.post(`${ApiEndpoints.UPDATE_WORKORDER_ITEMS}`, payload);
  return response;
}

export async function UpdateWorkorderStaff(payload) {
  const response = await api.post(`${ApiEndpoints.UPDATE_WORKORDER_STAFF}`, payload);
  return response;
}

export async function StartWorkorder(payload) {
  const response = await api.post(`${ApiEndpoints.START_WORKORDER}`, payload);
  return response;
}

export async function FinishWorkorder(payload) {
  const response = await api.post(`${ApiEndpoints.FINISH_WORKORDER}`, payload);
  return response;
}

export async function ApprovetWorkorder(payload) {
  const response = await api.post(`${ApiEndpoints.APPROVE_WORKORDER}`, payload);
  return response;
}

export async function Comment(payload) {
  const response = await api.post(`${ApiEndpoints.COMMENT}`, payload);
  return response;
}
