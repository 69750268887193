import { createSlice } from '@reduxjs/toolkit';
var initialState = {
  progress: 0,
};
export const topLoaderSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    changeProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
});

export default topLoaderSlice.reducer;
export const { changeProgress } = topLoaderSlice.actions;
