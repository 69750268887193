import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ActivityLog } from '../../../hooks/api/reportService';

const initialState = {
  data: [],
  error: '',
  loading: false,
};

const getActivityLogSlice = createSlice({
  name: 'activityLogSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActivityLogsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActivityLogsAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getActivityLogsAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const getActivityLogsAction = createAsyncThunk('activityLogAction', async (data, thunkApi) => {
  return ActivityLog(data)
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default getActivityLogSlice.reducer;
