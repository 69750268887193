import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PropertyDropDown } from '../../../hooks/api/propertyService';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

export const propertyDropdownSlice = createSlice({
  name: 'PropertyDropdownSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(propertyDropdownAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(propertyDropdownAction.fulfilled, (state, action) => {
      console.log('action', action);
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(propertyDropdownAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const propertyDropdownAction = createAsyncThunk('propertyDropdownAction', async (data, thunkApi) => {
  return PropertyDropDown(data)
    .then((res) => {
      console.log('res', res);
      return res;
    })
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default propertyDropdownSlice.reducer;
