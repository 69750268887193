import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { facility_manager, logowhite, house, guyWithPc } from '../../../Entryfile/imagepath';

const AuthPageDesign = ({ action }) => {
  return (
    <div className="overflow-y-hidden relative justify-center items-center w-0 hidden md:flex md:w-full bg-appcolor-900">
      <div
        className={`absolute inset-0 bg-no-repeat bg-cover bg-left`}
        style={{
          backgroundImage: `url(${guyWithPc})`,
          opacity: 1,
        }}
      ></div>
      <div className="mt-52 w-3/6 h-5/6 " style={{ width: '90%' }}>
        {/* <div className="w-full flex justify-center items-center">
          <img src={logowhite} className="w-52" alt="logo" />
        </div> */}
        {/* <div className="w-5/6 mt-9 mx-auto">
          <p className=" text-2xl lg:text-4xl text-white font-bold text-center">
            Manage and Get Real Estate <br className="hidden lg:block" /> Services Easily.
          </p>
        </div> */}
        {/* <div className="w-5/6 mt-10 mx-auto">
          <p className="flex items-center justify-center gap-2 text-base text-slate-400 font-semibold text-center">
            {action}
            <FiArrowRight />
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default AuthPageDesign;
