import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import Main from './Entryfile/Main';
import 'rsuite/dist/rsuite.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { PermissionProvider } from './shared/context/permissionsContext';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { store } from './shared/store';
import { injectStore } from './shared/utils/api';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tooltip/dist/react-tooltip.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3,
      cacheTime: 0,
    },
  },
});
injectStore(store);
Modal.setAppElement('#root');

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PermissionProvider>
          <Main />
        </PermissionProvider>
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>,

  document.getElementById('root'),
);
