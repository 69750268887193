import { toast } from 'react-toastify';
import { loginAction } from '../store/slices/Auth/loginSlice';
import { verifyOtpAction } from '../store/slices/Auth/otpSlice';
import { partnerSignInAction } from '../store/slices/Auth/partnerSlice';
import { registerAsyncAction } from '../store/slices/Auth/registerSlice';
import { addcollectionMembersAction } from '../store/slices/collection/addMoreCollectionMembers';
import { collectionByUserAction } from '../store/slices/collection/collectionByUserSlice';
import { createCollectionAction } from '../store/slices/collection/createCollectionSlice';
import { assignStaffToCommunityAction } from '../store/slices/community/assignStaffToCommunity';
import { createCommunityAction } from '../store/slices/community/createCommunitySlice';
import { getPartnerCommunityAction } from '../store/slices/community/partnerCommunities';
import { updateCommunityAction } from '../store/slices/community/updateCommunity';
import { UpdateCommunityStaffManagerAction } from '../store/slices/community/updateCommunityStaffsManager';
import { imageUploadAsyncAction } from '../store/slices/image/imageUploadSlice';
import { addPartnerBanksAction } from '../store/slices/partner/addPartnerBankSlice';
import { partnerAddStaffAction } from '../store/slices/partner/addStaff';
import { createPartnerAction } from '../store/slices/partner/createPartnerSlice';
import { getBanksAction } from '../store/slices/partner/getBankSlice';
import { partnerStaffsAction } from '../store/slices/partner/partnerStaffs';
import { verifyBanksAction } from '../store/slices/partner/verifyBankSlice';
import { addResidentDocumentAction } from '../store/slices/property/addResidentDocumentSlice';
import { addResidentRestrictionAction } from '../store/slices/property/addRestrictionSlice';
import { uploadPropertyBulkAction } from '../store/slices/property/uploadBulkProperty';
import { uploadResidentBulkAction } from '../store/slices/property/uploadBulkResident';
import { viewPropertyAction } from '../store/slices/property/viewProperty';
import { changePasswordAction } from '../store/slices/user/changePassword';
import { updateUserAction } from '../store/slices/user/updateUser';
import customToastComponent from './customToast';
import { disbursementApprovalAction } from '../store/slices/finance/disbursementApproval';
import { requestDisbursementAction } from '../store/slices/finance/requestDisbursement';
import { setDisbursementAction } from '../store/slices/finance/setDisbursement';

export const toastFunc = () => (next) => (action) => {
  switch (action.type) {
    case loginAction.rejected.type:
      customToastComponent(action.payload.message, true);
      break;
    case partnerSignInAction.fulfilled.type:
      if (action.payload === null) {
        toast.warn('Not a partner Yet Register', { theme: 'colored', position: 'top-right', toastId: 'so' });
      } else {
        // const id = toast('🦄 finally a partner', { theme: 'light', position: 'top-right' });
        customToastComponent('Finally a partner dweller, congrats!!');
      }
      break;
    case partnerSignInAction.rejected.type: {
      customToastComponent(action.payload || 'Problem Signin In', true);
      break;
    }
    case registerAsyncAction.rejected.type:
      customToastComponent(action.payload.message, true);
      break;
    case imageUploadAsyncAction.fulfilled.type:
      customToastComponent('Image Uploaded Successfully');
      break;
    case createPartnerAction.fulfilled.type:
      customToastComponent('Partner Created Awaiting Confirmation');
      break;
    case imageUploadAsyncAction.rejected.type:
      customToastComponent('Image Upload Failed', true);
      break;
    case createPartnerAction.rejected.type:
      customToastComponent('Create Partner Failed! ' + action.payload, true);
      break;
    case getBanksAction.rejected.type:
      customToastComponent('Unable to Fetch Banks', true);
      break;
    case verifyBanksAction.rejected.type:
      customToastComponent('Invalid Account ' + action.payload.message, true);
      break;
    case verifyOtpAction.rejected.type:
      customToastComponent('Verify OTP failed ' + action.payload.message, true);
      break;
    case addPartnerBanksAction.rejected.type:
      customToastComponent(action.payload, true);
      break;
    case addPartnerBanksAction.fulfilled.type:
      customToastComponent(action.payload.message);
      break;
    case createCollectionAction.fulfilled.type:
      customToastComponent('Created Successfully' + action.payload.message);
      break;
    case createCollectionAction.rejected.type:
      customToastComponent('Error Occurred While Creating Community ' + action.payload, true);
      break;
    case addResidentDocumentAction.fulfilled.type:
      customToastComponent(action.payload.message);
      break;
    case addResidentRestrictionAction.fulfilled.type:
      customToastComponent(action.payload.message);
      break;
    case collectionByUserAction.rejected.type:
      customToastComponent(action.payload, true);
      break;
    case partnerStaffsAction.rejected.type:
      customToastComponent('Error Occurred ' + action.payload.message, true);
      break;
    case createCommunityAction.fulfilled.type:
      customToastComponent(action.payload.message);
      break;
    case createCommunityAction.rejected.type:
      customToastComponent('Error Occured ' + action.payload.message, true);
      break;
    case getPartnerCommunityAction.rejected.type:
      customToastComponent('Error Occured ' + action.payload, true);
      break;
    case uploadPropertyBulkAction.rejected.type:
      customToastComponent('Error Uploading Property ' + action.payload, true);
      break;
    case uploadPropertyBulkAction.fulfilled.type:
      customToastComponent('Property Added to Queue');

      break;
    case updateUserAction.fulfilled.type:
      toast('' + action.payload.message, {
        theme: 'colored',
        toastId: 'so',
      });
      break;
    case updateUserAction.rejected.type:
      customToastComponent('Error Updating User ' + action.payload, true);
      break;
    case changePasswordAction.fulfilled.type:
      customToastComponent(action.payload.message);
      break;
    case changePasswordAction.rejected.type:
      customToastComponent('Error changing password - ' + action.payload, true);
      break;
    case addcollectionMembersAction.fulfilled.type:
      customToastComponent(action.payload.message, true);
      break;
    case addcollectionMembersAction.rejected.type:
      customToastComponent('Error adding Members' + action.payload, true);
      break;
    case partnerAddStaffAction.fulfilled.type:
      customToastComponent(action.payload.message);
      break;
    case partnerAddStaffAction.rejected.type:
      customToastComponent('Error Creating Staff' + action.payload, true);
      break;
    case uploadResidentBulkAction.fulfilled.type:
      customToastComponent('' + action.payload.message);
      break;
    case updateCommunityAction.rejected.type:
      customToastComponent('' + action.payload.message, true);
      break;
    case updateCommunityAction.fulfilled.type:
      customToastComponent(action.payload.message);
      break;
    case uploadResidentBulkAction.rejected.type:
      customToastComponent('Error Adding Resident' + action.payload, true);
      break;
    case assignStaffToCommunityAction.rejected.type:
      customToastComponent(action.payload, true);
      break;
    case UpdateCommunityStaffManagerAction.rejected.type:
      customToastComponent('Error ' + action.payload);
      break;
    case viewPropertyAction.rejected.type:
      customToastComponent('Error ' + action.payload, true);
      break;
    case disbursementApprovalAction.fulfilled.type:
      customToastComponent(action.payload.message);
      break;
    case disbursementApprovalAction.rejected.type:
      customToastComponent('Error ' + action.payload, true);
      break;
    case requestDisbursementAction.fulfilled.type:
      customToastComponent(action.payload.message);
      break;
    case requestDisbursementAction.rejected.type:
      customToastComponent(action.payload, true);
      break;
    case setDisbursementAction.fulfilled.type:
      customToastComponent(action.payload.message);
      break;
    case setDisbursementAction.rejected.type:
      customToastComponent(action.payload, true);
      break;
    default:
      break;
  }
  return next(action);
};

export const loadingBar = (params) => (state) => (next) => (action) => {
  if (state.loading === true) {
    next(action);
  } else {
    next(action);
  }
};

export const NoNetworkFunc = () => (next) => (action) => {
  if (navigator.onLine) {
    return next(action);
  } else {
    customToastComponent('No internet connection', true);
  }
};
