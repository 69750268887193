import { toast } from 'react-toastify';
import { CustomErrorToast, CustomSuccessToast } from '../components/customtoast';

const customToastComponent = (msg, error = false) => {
  let id = `kllm_${Math.random()}`;

  console.log(msg);
  if (error)
    toast(<CustomErrorToast mssg={msg} id={id} />, {
      position: 'top-right',
      closeButton: false,
      toastId: 'so',
    });
  else
    toast(<CustomSuccessToast mssg={msg} id={id} />, {
      position: 'top-right',
      closeButton: false,
      toastId: 'so',
    });
};

export default customToastComponent;
