import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssetDropdown } from '../../../hooks/api/assetService';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

export const assetDropdownSlice = createSlice({
  name: 'assetDropdownSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(assetDropdownAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(assetDropdownAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(assetDropdownAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const assetDropdownAction = createAsyncThunk('assetDropdownAction', async (data, thunkApi) => {
  return AssetDropdown(data)
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default assetDropdownSlice.reducer;
