import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetStaffCommunities } from '../../../hooks/api/communityService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const getStaffCommunitySlice = createSlice({
  name: 'staffCommunity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStaffCommunityAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStaffCommunityAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(getStaffCommunityAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const getStaffCommunityAction = createAsyncThunk('staffCommunity', async ({ data }, thunkApi) => {
  return GetStaffCommunities(data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default getStaffCommunitySlice.reducer;
