import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetAllCommunities } from '../../../hooks/api/communityService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const getPartnerCommunitySlice = createSlice({
  name: 'CreateCommunity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPartnerCommunityAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPartnerCommunityAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(getPartnerCommunityAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const getPartnerCommunityAction = createAsyncThunk('getPartnerCommunity', async ({ data }, thunkApi) => {
  console.log('partner summary', data);
  return GetAllCommunities(data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default getPartnerCommunitySlice.reducer;
