// import dotenv from 'dotenv';
// const BASE_URL = 'http://techleadng-002-site8.btempurl.com/api/';
// const BASE_URL = 'http://service.dweller.africa/api/';
// const BASE_URL = 'https://dweller.herokuapp.com/api/';
// dotenv.config({ path: '.env.test' });
const BASE_URL = process.env.REACT_APP_BASE_URL;
const IMAGE_BUCKET = 'https://storage.googleapis.com/dweller-bucket';
// const IMAGE_BUCKET = 'https://storage.googleapis.com/dweller-bucket';

const ApiEndpoints = {
  //Application Data
  STATES: 'v1/state',
  REGIONS: 'v1/regions',
  SUB_REGIONS: 'v1/subregions',

  // Users
  USER_SIGNUP: `v1/User/signup`,
  USER_SIGNIN: `v1/User/signin`,
  OTP_VERIFY: `v1/User/confirm_email`,
  CHANGE_PASSWORD: `v1/User/change_password`,
  RESET_PASSWORD: 'v1/User/reset_password',
  RESET_PASSWORD_LINK: 'v1/User/reset_password_link',

  UPDATE_USER: `v1/User`,

  /**  Partners */
  PARTNER_DETAILS: 'v1/Partner/detail',
  CREATE_PARTNER: 'v1/Partner/create-partner',
  ADD_PARTNER_BANK_ACCOUNT: 'v1/partner-banks',
  GET_PARTNER_ACCOUNT: 'v1/partner-banks',
  PARTNER_SIGNIN: 'v1/Partner/signin',
  SEARCH_USER: 'v1/Partner/searchuser',
  MY_COMMUNITIES: 'v1/Partner/my-communities',
  ALL_STAFFS: 'v1/Partner/all-staff',
  STAFF_DETAILS: 'v1/Partner/staffdetail',
  STAFF_COMMUNITIES: 'v1/Partner/getstaffcommunities',
  ADD_STAFFS: 'v1/Partner/addstaff',
  STAFF_SUMMARY: 'v1/Partner/staff-summary',
  UPDATE_MEMBER: 'v1/Partner/update-member',
  ASSIGN_STAFF: 'v1/Partner/assignstaff',
  UPDATE_MEMBER_PERMISSION: 'v1/Partner/update-member-permission',
  PARTNER_TRANSACTIONS: 'v1/partner-transactions',

  UPLOAD_IMAGE: 'v1/uploadImage',
  CHANGE_IMAGE: 'v1/User/uploadPhoto',

  //Banks
  GET_BANKS: 'Monnify/banks',
  VERIFY_BANK: 'Monnify/verify_bank',

  //Report
  REPORT_COLLECTION: 'v1/Report/collection',
  REPORT_PROPERTY: 'v1/Report/property',
  REPORT_COMMUNITYANDMANAGEMENT: 'v1/Report/communityandmanagerstats',
  ALL_PARTNER_LOG: 'v1/all-partner-log',
  REPORT_ADMIN_WORKORDER: 'v1/Report/wororderAdmin',
  REPORT_STAFF_WORKORDER: 'v1/Report/wororderStaff',
  REPORT_STAFF_COMMUNITY: 'v1/Report/staffcomm',

  //Finance
  PARTNER_WALLET_INFO: 'v1/partner-wallet-info',
  PARTNER_BONUS_ACCOUNT: 'v1/partner-bonus-account',
  PARTNER_DISBURSEMENT_SETUP: 'v1/partner-disbursement-setup',
  PARTNER_DISBURSEMENT_REQUESTS: 'v1/partner-disbursement-requests',
  PARTNER_DISBURSEMENT_APPROVAL: 'v1/partner-disbursement-approval',
  PARTNER_REQUEST_DISBURSEMENT: 'v1/partner-disbursement-request',

  //community
  CREATE_COMMUNITY: 'v1/create-community',
  VIEW_COMMUNITY: 'v1/view-community',
  UPDATE_COMMUNITY: 'v1/update-community',
  ALL_COMMUNITY: 'v1/partner-communities-all',
  STAFF_COMMUNITY: 'v1/staff-communities',
  VIEW_COMMUNITY_MEMBERS: 'v1/community-resident-dropdown',
  ADD_COMMUNITY_MEMBERS: 'v1/Community/add-community-members',
  VIEW_PARTNER_COMMUNITY: 'v1/partner-communities',
  COMMUNITY_SUMMARY: 'v1/community-summary',
  VIEW_DEVICES: 'v1/community-devices',
  PROPERTY_SUMMARY: 'v1/property-summary',
  RESIDENT_SUMMARY: 'v1/resident-summary',
  COMMUNITY_STAFFS: 'v1/communitystaff',
  REMOVE_COMMUNITY_STAFFS: 'v1/removestafffromcom',
  UPDATE_COMMUNITY_STAFFS_MANAGER: 'v1/removeasmanager',
  SUB_RESIDENT: 'v1/my-sub-resident',

  ALL_COMMUNITY_VISITS: 'v1/all-community-visit',
  COMMUNITY_VISIT_SUMMARY: 'v1/community-visit-summary',

  //collection
  CREATE_COLLECTION: 'v1/Collection/create-collection',
  ALL_COLLECTION: 'v1/Collection/all-collection',
  VIEW_COLLECTION: 'v1/Collection/view',
  VIEW_COLLECTION_MEMBERS: 'v1/Collection/view/members',
  ADD_COLLECTION_MEMBERS: 'v1/Collection/add-collection-members',
  COLLECTION_BY_USER: 'v1/Collection/user/collection',
  GET_PROPERTY_RESIDENT: 'v1/community/resident/download',

  //Property
  COMMUNITY_PROPERTY: 'v1/community-property',
  COMMUNITY_RESIDENTS: 'v1/get-all-communities-residents',
  ADD_RESIDENT: 'v1/add-residents',
  NEW_LIST_BULK: 'v1/new_listing-bulk',
  VIEW_PROPERTY: 'v1/viewProperty',
  VIEW_RESIDENTID: 'v1/resident-detail',
  GET_RESIDENT_TABLE: 'v1/get-document',
  RESIDENT_PARTNER_LOG: 'v1/resident-activity',
  ADD_RESIDENTDOCUMENT: 'v1/add-document',
  ADD_NOTIFICATION: 'v1/create-community-notice',
  GET_NOTIFICATION: 'v1/get-all-communities-notice',
  ADD_RESTRICTION: 'v1/add-restriction',
  REMOVE_RESTRICTION: 'v1/remove-restriction',

  // Assets
  ALL_ASSETS: 'v1/all-asset',
  ALL_ASSET_TYPE: 'v1/all-asset-type',
  VIEW_ASSET: 'v1/view-asset',
  CREATE_ASSET: 'v1/create-asset',
  CREATE_ASSET_TYPE: 'v1/create-asset-type',
  UPDATE_ASSET_STATUS: 'v1/update-asset-status',
  ALL_ASSET_ACTIVITIES: 'v1/all-asset-activities',
  REPORT_ALL_ASSET: 'v1/report-all-asset',
  REPORT_ASSETBYID: 'v1/report-assetbyId',

  // Property
  ALL_PARTNER_PROPERTY: 'v1/partner-property',
  PROPERTY_RESIDENT_HISTORY: 'v1/property-resident-history',

  // Maintenance
  ALL_MAINTENANCE: 'v1/all-maintenance',
  ALL_MAINTENANCE_SUMMARY: 'v1/all-maintenance-summary',
  MY_MAINTENANCE_SUMMARY: 'v1/mymaintenance-summary',
  VIEW_MAINTENANCE: 'v1/view-maintenance',
  CREATE_MAINTENANCE: 'v1/create-maintenance',

  ALL_SERVICE_REQUEST_TYPE: 'v1/all-servicerequest-type',
  CREATE_SERVICE_REQUEST_TYPE: 'v1/create-servicerequest-type',
  ALL_SERVICE_REQUEST_SUMMARY: 'v1/all-servicerequest-summary',
  MY_SERVICE_REQUEST_SUMMARY: 'v1/myservicerequest-summary',
  MY_JOBS: 'v1/my-jobs',
  WORKORDER_ACTIVITY: 'v1/workorderactivity',
  WORKORDER_STAFF: 'v1/view-workorder-staff',
  MAINTENANCE_ITEMS: 'v1/view-maintenance-items',
  COMMENT: 'v1/workorder/comment',

  UPDATE_WORKORDER_ITEMS: 'v1/updateworkorderitems',
  UPDATE_WORKORDER_STAFF: 'v1/updateworkorderstaff',

  START_WORKORDER: 'v1/workorder/start',
  APPROVE_WORKORDER: 'v1/workorder/approve',
  FINISH_WORKORDER: 'v1/workorder/finish',

  //dropdown
  ALL_ASSET_DROPDOWN: 'v1/all-asset-dropdown',
  COMMUNITY_DROPDOWN: 'v1/partner-communities-dropdown',
  PROPERTY_DROPDOWN: 'v1/property-dropdown',
  RESIDENT_DROPDOWN: 'v1/community-resident-dropdown',
  STAFF_DROPDOWN: 'v1/Partner/all-staff-dropdown',
};

export { BASE_URL, ApiEndpoints, IMAGE_BUCKET };
