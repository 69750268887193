import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PartnerTransactions } from '../../../hooks/api/partnerService';

const initialState = {
  loading: false,
  response: [],
  error: '',
};

const partnerTransactionSlice = createSlice({
  name: 'partnerTransactionSlice',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(partnerTransactionAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(partnerTransactionAction.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
    });
    builder.addCase(partnerTransactionAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const partnerTransactionAction = createAsyncThunk('partnerTransactionAction', async (data, thunkApi) => {
  return PartnerTransactions(data)
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default partnerTransactionSlice.reducer;
