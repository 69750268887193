import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetAllMaintenanceSummary } from '../../../hooks/api/maintenanceService';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const getAllMaintenanceSummarySlice = createSlice({
  name: 'getAllMaintenanceSummary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllMaintenanceSummaryAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllMaintenanceSummaryAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getAllMaintenanceSummaryAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const getAllMaintenanceSummaryAction = createAsyncThunk('getAllMaintenanceSummaryAction', (data, thunkApi) => {
  return GetAllMaintenanceSummary(data)
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default getAllMaintenanceSummarySlice.reducer;
