import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PartnerSignIn } from '../../../hooks/api/partnerService';
import { SignInUser } from '../../../hooks/api/userService';
import { GET_STORAGE_ITEM, SET_STORAGE_ITEM } from '../../../utils/storage';
import { changeProgress } from '../toploader/toploaderSlice';
import { partnerSignInAction } from './partnerSlice';

const initialState = {
  loading: false,
  error: null,
  token: GET_STORAGE_ITEM('token') || null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    changeLoading: (state, action) => {
      state.loading = action.payload;
    },
    reset: (state, action) => {
      state.loading = false;
      state.error = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginAction.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.loading = false;
    });
    builder.addCase(loginAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});
export const loginAction = createAsyncThunk('login', async ({ data, history }, thunkApi) => {
  thunkApi.dispatch(changeProgress(60));
  return (
    SignInUser(data)
      .then((response) => {
        console.log(response);
        SET_STORAGE_ITEM('token', response.token);
        thunkApi.dispatch(changeProgress(100));
        return response;
      })
      // .then(async (res) => {
      //   if (res.user.isEmailConfirmed || res.user.isPhoneConfirmed) {
      //     thunkApi.dispatch(changeProgress(60));
      //     thunkApi.dispatch(changeLoading(false));

      //     thunkApi.dispatch(changeProgress(100));
      //     await thunkApi.dispatch(partnerSignInAction({ data, history }));
      //     return res;
      //   } else {
      //     history.push('/otp');
      //     return res;
      //   }
      // })

      .catch((error) => {
        thunkApi.dispatch(changeProgress(100));
        history.push('/unverified');
        console.log(error, 'from login slice');
        // return thunkApi.rejectWithValue(error);
      })
  );
});

export default loginSlice.reducer;
export const { changeLoading, reset } = loginSlice.actions;
