import AsyncInput from 'react-select/async';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => {
    // console.log(provided);
    return {
      ...provided,
      color: '#1a2b46',
      fontSize: '13px',
      borderBottom: '1px dotted pink',
      color: state.isSelected ? '#297d4e' : '#1a2b46',
      padding: '8px 12px',
      zIndex: 22,
    };
  },
  noOptionsMessage: (provided, state) => {
    return {
      ...provided,
      // color: '#1a2b46',
      // fontSize: '15px',
      // borderBottom: '1px dotted pink',
      // color: state.isSelected ? 'red' : 'blue',
      // padding: 20,
    };
  },
  container: (provided, state) => ({
    ...provided,

    borderColor: 'none',
    border: 'none',
    width: '100%',
  }),

  menu: (provided, state) => {
    return {
      ...provided,
      color: '#1a2b46',
      fontSize: '15px',
      borderBottom: '1px dotted pink',
    };
  },
  input: (provided, state) => ({
    ...provided,

    borderColor: state.isFocused ? '#F7F7F7' : null,
  }),
  control: (provided, state) => {
    return {
      ...provided,
      '&:hover': {
        borderColor: '#000000',
      },
      background: '#fff',
      borderColor: '#000000',
      border: '1px solid #B2BEB5',
      boxShadow: '0 0 0 2px #ccc',
      margin: '0 0 0 4px',
      padding: '1px 3px 1px 3px',
      borderColor: state.isFocused ? '#F7F7F7' : null,
      width: '100%',
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};
const customStyles2 = {
  option: (provided, state) => {
    // console.log(provided);
    return {
      ...provided,
      color: '#1a2b46',
      fontSize: '13px',
      borderBottom: '1px dotted pink',
      color: state.isSelected ? '#297d4e' : '#1a2b46',
      padding: '8px 12px',
      backgroundColor: state.isSelected ? '#B2BEB5' : null,
      zIndex: 22,
    };
  },
  noOptionsMessage: (provided, state) => {
    return {
      ...provided,
      // color: '#1a2b46',
      // fontSize: '15px',
      // borderBottom: '1px dotted pink',
      // color: state.isSelected ? 'red' : 'blue',
      // padding: 20,
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      color: '#1a2b46',
      fontSize: '15px',
      border: 'none',
      borderBottom: '1px dotted pink',
      zIndex: 22,
    };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      color: '#1a2b46',
      fontSize: '13px',
    };
  },
  input: (provided, state) => ({
    ...provided,

    borderColor: 'none',
    border: 'none',
    padding: '1px ',

    width: '100%',
  }),
  container: (provided, state) => ({
    ...provided,

    borderColor: 'none',
    border: 'none',
    width: '100%',
  }),
  valueContainer: (provided, state) => ({
    ...provided,

    borderColor: 'none',
    border: 'none',
    fontSize: '13px',
    height: 'auto',

    padding: '1px 7px 1px 0px',
  }),
  control: (provided, state) => {
    return {
      ...provided,
      '&:hover': {
        borderColor: '#000000',
      },
      background: '#fff',
      borderColor: '#000000',
      border: 'none',
      margin: '0 0 0 4px',
      padding: '1px 3px 1px 3px',
      borderColor: 'none',
      width: '100%',
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

export const CustomSelect2 = ({
  onChangeFunction,
  options,
  dropdownIndicator = true,
  placeholder = '',
  value,
  defaultValue,
  style = 1,
}) => {
  return (
    <Select
      //styles={style === 1 ? customStyles2 : customStyles}
      styles={{
        option: (provided, state) => {
          // console.log(provided);
          return {
            ...provided,
            color: '#1a2b46',
            fontSize: '13px',
            borderBottom: '1px dotted pink',
            color: state.isSelected ? '#297d4e' : '#1a2b46',
            padding: '8px 12px',
            backgroundColor: state.isSelected ? '#B2BEB5' : null,
            zIndex: 22,
          };
        },
        menu: (provided, state) => {
          return {
            ...provided,
            color: '#1a2b46',
            fontSize: '15px',
            border: 'none',
            borderBottom: '1px dotted pink',
            zIndex: 22,
          };
        },
        placeholder: (provided, state) => {
          return {
            ...provided,
            color: '#1a2b46',
            fontSize: '13px',
          };
        },
        input: (provided, state) => ({
          ...provided,
          border: 'none',
        }),
        container: (provided, state) => ({
          ...provided,

          borderColor: 'none',
          border: 'none',
          width: '100%',
        }),
        valueContainer: (provided, state) => ({
          ...provided,

          borderColor: 'none',
          border: 'none',
          fontSize: '13px',
          height: 'auto',

          padding: '1px 7px 1px 0px',
        }),
        control: (provided, state) => {
          return {
            ...provided,
            background: '#fff',

            border: '1px solid black',
            border: state.isFocused ? '1px solid black' : '1px solid black',
            margin: '0 0 0 4px',
            outline: state.isFocused ? 0 : 0,
            padding: '1px 3px 1px 3px',
            boxShadow: 'none',
            '&:hover': {
              borderColor: 0,
            },
            width: '100%',
          };
        },
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';

          return { ...provided, opacity, transition };
        },
      }}
      // defaultValue={defaultValue ? options[defaultValue] : null}
      isClearable
      isSearchable
      closeMenuOnScroll
      options={options}
      onChange={(val) => onChangeFunction(val)}
      placeholder={<span className="text-xs md:text-sm">{placeholder}</span>}
      value={value}
    />
  );
};

const CustomSelect = ({
  onChangeFunction,
  loadOptions,
  defaultOptions,
  modal = false,
  setModalOpen,
  placeholder = 'Search for community',
  value,
}) => {
  return (
    <AsyncInput
      styles={customStyles}
      loadOptions={loadOptions}
      touchUi={true}
      cacheOptions
      isSearchable
      defaultOptions={defaultOptions}
      openMenuOnFocus={true}
      openMenuOnClick={true}
      noOptionsMessage={({ input }) =>
        modal ? (
          <h3>
            Nothing here{' '}
            <span className="font-bold text-indigo-900 cursor-pointer" onClick={() => setModalOpen(true)}>
              Add Now
            </span>
          </h3>
        ) : null
      }
      onBlur={(event) => event.preventDefault()}
      placeholder={<span className="text-xs md:text-sm">{placeholder}</span>}
      onChange={(val) => onChangeFunction(val)}
      value={value ?? null}
    />
  );
};

export default CustomSelect;
