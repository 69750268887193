import { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Formik, ErrorMessage } from 'formik';
import { FcGoogle } from 'react-icons/fc';
import { BsFacebook, BsTwitter } from 'react-icons/bs';
import * as Yup from 'yup';
import { authOverlay, logo } from '../../../Entryfile/imagepath';
import { Input } from '../../../shared/components/formInputs';
import Button from '../../../shared/components/button';
import CustomToast, { CustomSuccessToast } from '../../../shared/components/customtoast';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, reset } from '../../../shared/store/slices/Auth/loginSlice';
import { partnerSignInAction } from '../../../shared/store/slices/Auth/partnerSlice';
import { MdMarkEmailUnread, MdOutlineClose } from 'react-icons/md';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { HiLockOpen } from 'react-icons/hi';
import AuthPageDesign from '../../../shared/components/misc/AuthPageDesign';

const getFormProps = () => {
  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  return {
    initialValues,
    validationSchema,
  };
};

const Login = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState('password');
  const { loading } = useSelector((state) => state.login);

  const changeShowPassword = () => setShowPassword(!showPassword);

  async function handleSubmit(values) {
    const { username, password } = values;
    const payload = {
      username,
      password,
      appId: username,
    };

    dispatch(partnerSignInAction({ data: payload, history }));
  }

  useEffect(() => {
    if (showPassword) setInputType('text');
    else setInputType('password');
  }, [showPassword]);

  return (
    <>
      <div className="text-sm flex md:flex-row flex-col w-full xl:h-screen 3xl:overflow-y-hidden relative">
        <AuthPageDesign action={'Login to your account'} />
        <div className="absolute top-0 bottom-0 w-full px-3 md:px-0 md:w-5/12 h-screen flex flex-col relative justify-center items-center">
          <Link to="/" className="fixed text-appcolor-900 z-10 right-3 top-6 md:top-3 hover:border border-inset">
            <MdOutlineClose size={20} />
          </Link>
          <div
            className={`absolute  md:hidden inset-0 bg-no-repeat bg-cover`}
            style={{
              background: `url(${authOverlay})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              opacity: 1,
            }}
          ></div>
          <div className="w-full md:w-[85%] relative max-w-[500px] h-full flex flex-col justify-center items-center">
            <div className="absolute top-0 left-0 mt-4 md:hidden flex items-center">
              <img src={logo} className="w-36 h-10" />
            </div>
            <div
              className=" w-full rounded py-8 px-3 md:p-0 bg-white md:shadow-none md:bg-transparent"
              style={{ marginTop: '30px', marginBottom: '50px' }}
            >
              <div className="mt-10 md:mt-[unset] flex justify-between md:justify-start mb-5">
                <div className="text-base text-center mx-auto">
                  <p className="text-xl md:text-3xl md:mb-2 mb-1 font-bold">Welcome back.</p>
                  <p>
                    New to dweller?{' '}
                    <Link className="font-semibold text-appcolor-400 underline" to="/signup">
                      Sign up
                    </Link>{' '}
                  </p>
                </div>
              </div>
              <div>
                <Formik
                  onSubmit={handleSubmit}
                  validateOnMount={true}
                  initialValues={getFormProps().initialValues}
                  validationSchema={getFormProps().validationSchema}
                >
                  {({ isSubmitting, isValid }) => (
                    <>
                      <div className="text-center">
                        <div className="">
                          <div className="flex justify-center"></div>
                          <div>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <Form style={{ width: '100%' }}>
                        <Input>
                          <div className="relative">
                            <Input.InputField
                              type="text"
                              name="username"
                              otherclass="py-2.5 !text-sm mb-2"
                              label={'Your email address'}
                              placeholder=""
                            ></Input.InputField>
                            <ErrorMessage
                              className="-mt-2.5 text-xs text-left text-red-600"
                              component="div"
                              name="username"
                            />
                            <MdMarkEmailUnread className="absolute right-3 top-11" size={20} color="#23395d" />
                          </div>
                          <div className="relative mt-4">
                            <Input.InputField
                              type={inputType}
                              name="password"
                              otherclass="py-2.5 !text-sm"
                              label={'Your password'}
                              placeholder=""
                              autoComplete="new-password"
                            ></Input.InputField>
                            <ErrorMessage
                              className="-mt-2.5 text-xs text-left text-red-600"
                              component="div"
                              name="password"
                            />
                            <div role="button" className="absolute right-3 top-11">
                              {!showPassword ? (
                                <BsEyeSlash size={18} color="#111c2e" onClick={changeShowPassword} />
                              ) : (
                                <BsEye size={18} color="#111c2e" onClick={changeShowPassword} />
                              )}
                            </div>
                          </div>
                          <div className="flex justify-between mt-3">
                            <div className="flex items-center justify-center">
                              <Input.CheckBox type="checkbox" name="remember-me"></Input.CheckBox>
                              <label
                                // role="label"
                                htmlFor="remember-me"
                                className="mb-0 text-xs font-semibold"
                              >
                                Remember Me
                              </label>
                            </div>
                            <div>
                              <Link
                                to={'/reset-password'}
                                className="hover:text-blue-900 hover:underline font-bold text-sm"
                                style={{ color: 'gray' }}
                              >
                                Forgot Password
                              </Link>
                            </div>
                          </div>
                          <div>
                            <Button
                              className="flex"
                              type="submit"
                              disabled={isSubmitting || !isValid}
                              isLoading={isSubmitting || loading}
                              style={{
                                width: '100%',
                                gap: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'centers',
                              }}
                            >
                              <HiLockOpen size={20} />
                              <span>Login</span>
                            </Button>
                          </div>
                        </Input>
                      </Form>
                    </>
                  )}
                </Formik>
                {/* <div className="w-full my-3 text-center flex justify-center items-center">
                  {' '}
                  <span className="text-sm text-grey-200">---------------</span>{' '}
                  <span className="text-lg mx-2 font-bold">or sign in with</span>
                  <span className="text-sm text-grey-200">---------------</span>{' '}
                </div>
                <div className="grid grid-cols-2 gap-5 px-3">
                  <button className="group hover:bg-appcolor-600 hover:text-white transition duration-500 border p-3 my-2 rounded shadow flex items-center justify-center ">
                    <FcGoogle size={24} />
                    <span className="ml-2 text-base font-semibold">Google</span>
                  </button>
                  <button className="group hover:bg-appcolor-600 hover:text-white transition duration-500 border p-3 my-2 rounded shadow flex items-center justify-center ">
                    <BsFacebook className="hover:text-white" size={24} color="#1a2b46" />
                    <span className="ml-2 text-base font-semibold">Facebook</span>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <p className="text-left absolute bottom-3 left-[10%] text-xs font-medium">
            Copyright © 2023 Dweller Solutions version 1.0.0
          </p>
        </div>
      </div>
      <CustomToast />
    </>
  );
};

export default Login;
