import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetPartnerWalletInfo } from '../../../hooks/api/financeService';
import { CollectionReport } from '../../../hooks/api/reportService';
import { getRegions } from '../../../hooks/api/stateService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: true,
  error: null,
  response: null,
};

const getWalletInfoSlice = createSlice({
  name: 'walletInfoSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWalletInfoAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWalletInfoAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(getWalletInfoAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const getWalletInfoAction = createAsyncThunk('getWalletInfoAction', async (data, thunkApi) => {
  return GetPartnerWalletInfo()
    .then((res) => {
      return res;
    })

    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default getWalletInfoSlice.reducer;
