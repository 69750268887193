import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CollectionByUser, CreateCollection } from '../../../hooks/api/collectionService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const collectionByUserSlice = createSlice({
  name: 'CollectionByUser',
  initialState,
  reducers: {
    resetUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(collectionByUserAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(collectionByUserAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(collectionByUserAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const collectionByUserAction = createAsyncThunk('collectionByUser', async ({ data }, thunkApi) => {
  return CollectionByUser(data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default collectionByUserSlice.reducer;
export const { resetUser } = collectionByUserSlice.actions;
