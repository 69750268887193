import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetCommunityVisitSummay } from '../../../hooks/api/communityService';

const initialState = {
  response: null,
  loading: false,
  error: '',
};

const communityVisitorSummarySlice = createSlice({
  name: 'communityVisitorSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(communityVisitSummaryAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(communityVisitSummaryAction.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
    });
    builder.addCase(communityVisitSummaryAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const communityVisitSummaryAction = createAsyncThunk('communityVisitSummaryAction', async (data, thunkApi) => {
  return GetCommunityVisitSummay(data)
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err));
});

export default communityVisitorSummarySlice.reducer;
