import { Field } from 'formik';

const InputField = ({ children, type, label, aClass, dClass, inputType, onChange, ...props }) => {
  const Text = inputType;
  return (
    <div className={`grid gap-1 ${dClass}`}>
      <label htmlFor={props.name} className="ont- text-appcolor-900">
        {label}
      </label>
      <Text
        id={props.name}
        name={props.name}
        className={`rounded border-[#dee5e7] focus:border-none ${aClass}`}
        type={type ? type : 'text'}
        onChange={onChange}
      />
    </div>
  );
};
export const TextInputField = ({ children, label, aClass, dClass, inputType, onChange, ...props }) => {
  const textarea = inputType;
  return (
    <div className={`grid gap-1 ${dClass}`}>
      <label htmlFor={props.name} className="ont- text-appcolor-900">
        {label}
      </label>
      <textarea
        id={props.name}
        name={props.name}
        className={`rounded border-[#dee5e7] focus:border-none  ${aClass}`}
        type="text"
        onChange={onChange}
      />
    </div>
  );
};

export const DateInputField = ({ children, label, aClass, dClass, inputType, onChange, ...props }) => {
  const Date = inputType;
  return (
    <div className={`grid gap-1 ${dClass}`}>
      <label htmlFor={props.name} className="ont- text-appcolor-900">
        {label}
      </label>
      <Date
        id={props.name}
        name={props.name}
        className={`rounded border-[#dee5e7] focus:border-none  ${aClass}`}
        type="text"
        onChange={onChange}
      />
    </div>
  );
};

export const InputFormikField = ({ children, label, as, ...props }) => {
  return (
    <div className={`grid gap-1 `}>
      <label htmlFor={props.name} className="ont- text-appcolor-900">
        {label}
      </label>
      <Field as={as} name={props.name} className="rounded border-[#dee5e7]" {...props}>
        {children}
      </Field>
    </div>
  );
};
export const SelectInputField = ({ children, label, aClass, ...props }) => {
  return (
    <div className={`flex flex-col items-start w-full px-3 my-5 border border-gray-400 py-1 rounded ${aClass}`}>
      <label htmlFor={props.name} className="text-sm text-gray-500">
        {label}
      </label>
      {children}
    </div>
  );
};

export const AnotherInputField = ({
  className = 'form-input px-3 py-2 bg-transparent w-full border-none rounded focus:ring-0  focus:outline-0 focus:border-none ',
  inputType,
  label = false,
  labelName,
  borderStyle = 'border border-gray-500 rounded shadow',
  children,
  ...props
}) => {
  const Text = inputType;
  return (
    <div className={borderStyle}>
      {label && <label className="text-base mb-1 text-appcolor-900">{labelName}</label>}
      <Text className={className} {...props}>
        {children}
      </Text>
    </div>
  );
};

export default InputField;
