import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import NaijaStates from 'naija-state-local-government';
import { Formik, Field, Form } from 'formik';
import Button from '../../../shared/components/button';
import InputField, { InputFormikField } from '../../../shared/components/customforminput';
import ImageComponent from './imageupload';
import { useEffect, useState } from 'react';
import { FaUser, FaUsers } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { createPartnerAction } from '../../../shared/store/slices/partner/createPartnerSlice';
import { getStatesAction } from '../../../shared/store/slices/states_regions/getStatesSlice';

let schema = yup.object().shape({
  name: yup.string().required(),
  stateOfOperation: yup.string().required(),
  companyRegistrationNumber: yup.string().required(),
  partnerType: yup.number().min(1).required(),
  companyLogo: yup.string().required(),
  address: yup.string().required(),
  website: yup.string(),
  phone: yup.string().required(),
  email: yup.string().email().required(),
});

const BusinessProfile = ({ nextStep, imageUrl, setImageUrl }) => {
  const [partnerType, setPartnerType] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.createPartner);
  const { response: allstates } = useSelector((state) => state.getStates);

  useEffect(() => {
    dispatch(getStatesAction());
  }, []);
  const initialValues = {
    name: '',
    stateOfOperation: '',
    companyRegistrationNumber: '',
    partnerType: 1,
    companyLogo: '',
    website: '',
    address: '',
    phone: '',
    email: '',
  };
  const onSubmit = (values) => {
    // values.partnerType = partnerType === 'single' ? 1 : 2;
    // dispatch(createPartnerAction(values)).then((res) => {
    //   if (res.meta.requestStatus === 'fulfilled') {
    //     nextStep();
    //   }
    // });
    dispatch(createPartnerAction({ data: values, history }));
  };

  return (
    <Formik onSubmit={onSubmit} validateOnMount initialValues={initialValues} validationSchema={schema}>
      {({ isValid, isSubmitting, getFieldProps, setFieldValue }) => {
        return (
          <Form>
            <>
              <div className="w-full mt-10 text-center">
                <h3 className="text-xl font-bold">Business Profile Form</h3>
              </div>
              <div className="px-8 py-8 bg-white my-8 mb-0 ">
                <div className=" ">
                  <p className="px-2 mb-2 text-lg text-appcolor-900 font-medium">Select Account Type</p>
                  <div className="grid grid-cols-2 border text-center mb-10">
                    <div
                      onClick={() => {
                        setPartnerType(2);
                        setFieldValue('partnerType', partnerType);
                      }}
                      className={`${
                        partnerType === 2 && 'bg-appcolor-500 !border-appcolor-500 text-white'
                      } cursor-pointer flex flex-col items-center gap-3 py-10`}
                    >
                      {' '}
                      <FaUsers size={50} className="-mt-1" />
                      <p className="max-w-[150px] -mt-2">Multiple Community Owner</p>
                    </div>
                    <div
                      onClick={() => {
                        setPartnerType(1);
                        setFieldValue('partnerType', partnerType);
                      }}
                      className={`${
                        partnerType === 1 && 'bg-appcolor-500 !border-appcolor-500 text-white'
                      } cursor-pointer flex flex-col items-center py-10 gap-3 border-r`}
                    >
                      <FaUser size={40} />
                      <p className="max-w-[150px]">Single Community Owner</p>
                    </div>
                  </div>
                </div>

                <h3 className="text-xl font-medium">Business Details</h3>
                <div className="grid grid-cols-2 gap-3">
                  <div className="my-1">
                    <InputField
                      inputType={Field}
                      name="name"
                      type="text"
                      label={'Company Name'}
                      placeholder=""
                      {...getFieldProps('name')}
                    />
                  </div>
                  <div className="my-1">
                    <InputFormikField
                      name="stateOfOperation"
                      as={'select'}
                      //onChange={(i) => console.log(i)}
                      label={'State of operation'}
                      placeholder=""
                    >
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        return (
                          <select
                            onChange={(i) => {
                              setFieldValue('stateOfOperation', i.target.value);
                            }}
                            //value={selectedstate}
                            className="rounded overflow-hidden w-full overflow-y-scroll text-appcolor-900 py-2"
                          >
                            <option value="">Select Item</option>
                            {allstates &&
                              allstates.map((item, i) => {
                                return (
                                  <option value={item?.value} key={i}>
                                    {item?.text}
                                  </option>
                                );
                              })}
                          </select>
                        );
                      }}
                    </InputFormikField>
                  </div>
                </div>
                <div className="mt-5 mb-5">
                  <ImageComponent
                    // setFieldValue={setFieldValue}
                    // imageUrl={imageUrl}
                    // setImageUrl={setImageUrl}
                    onChangeFunction={(i) => setFieldValue('companyLogo', i)}
                    {...getFieldProps('companyLogo')}
                  />
                </div>

                <div className="my-1 ">
                  <InputField
                    inputType={Field}
                    type="text"
                    name="companyRegistrationNumber"
                    label={'Company Registeration Number'}
                    placeholder=""
                    {...getFieldProps('companyRegistrationNumber')}
                  />
                </div>
                {/* <ImageComponent
                  onChangeFunction={(val) => {
                    setImageUrl(val);
                    setFieldValue('companyLogo', val);
                  }}
                  {...getFieldProps('companyLogo')}
                /> */}
                <h3 className="font-medium text-xl mt-10">Business Contact Information</h3>
                <div className="grid grid-cols-2 gap-3">
                  <div className="my-2">
                    <InputField
                      inputType={Field}
                      type="text"
                      name="email"
                      label={'Contact Email'}
                      placeholder=""
                      {...getFieldProps('email')}
                    />
                  </div>
                  <div className="my-2">
                    <InputField
                      inputType={Field}
                      type="text"
                      name="phone"
                      label={'Phone No'}
                      placeholder=""
                      {...getFieldProps('phone')}
                    />
                  </div>
                </div>
                <div className="my-2 ">
                  <InputField
                    inputType={Field}
                    type="text"
                    name="address"
                    label={'Contact Address'}
                    placeholder=""
                    {...getFieldProps('address')}
                  />
                </div>
                <div className=" w-full mt-8">
                  <Button
                    type="submit"
                    isLoading={loading}
                    disabled={loading || !isValid}
                    aClassName={'w-full'}
                    // onClick={() => nextStep()}
                  >
                    Save and Next
                  </Button>
                </div>
              </div>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BusinessProfile;
