import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { addNotification, getNotification } from '../../../hooks/api/propertyService';

const initialState = {
  response: null,
  loading: false,
  error: null,
};

const getNotificationDataSlice = createSlice({
  name: 'getNotificationData',
  initialState: initialState,

  reducers: {
    defaultState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationAction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getNotificationAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });

    builder.addCase(getNotificationAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});
export const getNotificationAction = createAsyncThunk('getNotification', async (data, thunkApi) => {
  // console.log('getNotifications',data);

  return await getNotification(data)
    .then((res) => {
      return res;
    })

    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default getNotificationDataSlice.reducer;

export const { defaultState } = getNotificationDataSlice.actions;
