import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AddPartnerBank, PartnerAllStaff } from '../../../hooks/api/partnerService';

const initialState = {
  loading: false,
  error: null,
  response: null,
  responseFull: null,
};

const partnerStaffsSlice = createSlice({
  name: 'getStaffsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(partnerStaffsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(partnerStaffsAction.fulfilled, (state, action) => {
      let dd = action.payload.data.map((item) => ({
        value: item.id,
        label: `${item.user.firstName} ${item.user.lastName}`,
      }));
      state.response = dd;
      state.responseFull = action.payload;
      state.loading = false;
    });
    builder.addCase(partnerStaffsAction.rejected, (state, action) => {
      console.log('action', action);
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const partnerStaffsAction = createAsyncThunk('getstaffsAction', async (data, thunkApi) => {
  return PartnerAllStaff(data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default partnerStaffsSlice.reducer;
