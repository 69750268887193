import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ApprovetWorkorder, ViewMaintenance } from '../../../hooks/api/maintenanceService';
import customToastComponent from '../../../utils/customToast';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const approveWorkorderSlice = createSlice({
  name: 'approveWorkorderSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(approveWorkorderAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(approveWorkorderAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(approveWorkorderAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const approveWorkorderAction = createAsyncThunk('approveWorkorderAction', async (data, thunkApi) => {
  return ApprovetWorkorder({ workOrderId: data?.workOrderId })
    .then((res) => {
      customToastComponent(res?.message || 'Approved Successfully');
      return res;
    })
    .catch((err) => {
      customToastComponent(err?.message ?? 'Error. Try again', true);
      thunkApi.rejectWithValue(err.message);
    });
});

export default approveWorkorderSlice.reducer;
