import api from '../../utils/api';
import { useMutation } from 'react-query';
import { ApiEndpoints, BASE_URL } from '../../config/Endpoints';
import axios from 'axios';

export async function GetBanks() {
  const response = await api.get(`${ApiEndpoints.GET_BANKS}`);
  return response;
}

export async function Verify_bank({ bankcode, account_number }) {
  const response = await axios.get(
    `${BASE_URL}${ApiEndpoints.VERIFY_BANK}?BankCode=${bankcode}&AccountNumber=${account_number}`,
  );
  return response.data;
}

export default {
  useGetBankService: (...args) => useMutation(GetBanks, ...args),
  useVerifyBankService: (...args) => useMutation(Verify_bank, ...args),
};
