import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { addResidentDoc, getCommunityResidentId } from '../../../hooks/api/propertyService';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const addResidentDocument = createSlice({
  name: 'addResidentDocument',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addResidentDocumentAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addResidentDocumentAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(addResidentDocumentAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const addResidentDocumentAction = createAsyncThunk('addResidentDocument', async (data, thunkApi) => {
  console.log('add document Data', data);
  return addResidentDoc(data)
    .then((res) => {
      return res;
    })

    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default addResidentDocument.reducer;
