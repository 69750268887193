import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Comment } from '../../../hooks/api/maintenanceService';
import customToastComponent from '../../../utils/customToast';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const commentSlice = createSlice({
  name: 'commentSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(commentAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(commentAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(commentAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const commentAction = createAsyncThunk('commentAction', async (data, thunkApi) => {
  return Comment(data)
    .then((res) => {
      customToastComponent(res?.data?.message ?? 'Comment Created Successfully')
      return res;
    })
    .catch((err) => {
      customToastComponent(err?.message ?? 'Error Creating Comment', true)
      thunkApi.rejectWithValue(err.message);
    });
});

export default commentSlice.reducer;
