import React from 'react';
import { Link } from 'react-router-dom';
import { StyledButton } from './styles';

import waveLoader from '../../../assets/images/Loading-bar-White.gif';

const ButtonLoader = () => (
  <div className="mx-auto flex justify-center text-white text-sm">
    <div className="spinner-border mr-1 animate-spin inline-block w-5 h-5 rounded-full" role="status">
      <span className="visually-hidden">
        <svg
          className=" -inline-block w-4 h-4 border-2 rounded-full"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          {/* <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path> */}
        </svg>
      </span>
    </div>
    <div className="spinner-border mr-1 animate-spin inline-block w-5 h-5 rounded-full" role="status">
      <span className="visually-hidden">
        <svg
          className=" -inline-block w-4 h-4 border-2 rounded-full"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          {/* <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path> */}
        </svg>
      </span>
    </div>
    <div className="spinner-border  animate-spin inline-block w-5 h-5 rounded-full" role="status">
      <span className="visually-hidden">
        <svg
          className=" -inline-block w-4 h-4 border-2 rounded-full"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          {/* <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path> */}
        </svg>
      </span>
    </div>
  </div>
);

const Button = React.forwardRef(
  ({ children, variant, size, disabled, onClick, aClassName, isLoading, ...restProps }, ref) => {
    const handleClick = () => {
      if (!disabled && !isLoading) {
        onClick();
      }
    };

    console.log(isLoading);

    return (
      <StyledButton
        {...restProps}
        variant={variant}
        disabled={disabled}
        size={size}
        className={`!flex !justify-center items-center ${aClassName}`}
        onClick={handleClick}
        ref={ref}
      >
        {isLoading ? <img className='white w-14 !h-12' src={waveLoader} alt='wave loader'/> : children}
        {/* {isLoading ? <ButtonLoader /> : children} */}
      </StyledButton>
    );
  },
);

Button.defaultProps = {
  className: undefined,
  children: undefined,
  variant: 'primary',
  size: 'lg',
  disabled: false,
  isLoading: false,
  onClick: () => {},
};

export const Button2 = ({ children, classes, ...props }) => {
  return (
    <button
      className={`bg-appcolor-600 cursor-pointer hover:bg-appcolor-900 py-2 text-white rounded-md px-4 ${classes}`}
      {...props}
    >
      {children}
    </button>
  );
};
export const LinkButton = ({ children, classes, ...props }) => {
  return (
    <a
      className={`bg-appcolor-600 cursor-pointer hover:bg-appcolor-900 py-2 text-white rounded-md px-4 ${classes}`}
      {...props}
    >
      {children}
    </a>
  );
};

export default Button;
