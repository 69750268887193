import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { addRestriction } from '../../../hooks/api/propertyService';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const addResidentRestrictionSlice = createSlice({
  name: 'addResidentRestriction',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addResidentRestrictionAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addResidentRestrictionAction.fulfilled, (state, action) => {
      state.response = action.payload;
      console.log('My Payload fulfiled', action.payload);
      state.loading = false;
    });
    builder.addCase(addResidentRestrictionAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const addResidentRestrictionAction = createAsyncThunk('restrict', async (data, thunkApi) => {
  return addRestriction(data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default addResidentRestrictionSlice.reducer;
