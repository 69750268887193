import React, { useState } from 'react';
import { authOverlay, logo, logowhite } from '../../../Entryfile/imagepath';
import Button from '../../../shared/components/button';
import OtpInput from 'react-otp-input';
import CustomToast from '../../../shared/components/customtoast';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifyOtpAction } from '../../../shared/store/slices/Auth/otpSlice';
// import { toast, ToastContainer } from 'react-toastify';
// import { useAuthState } from '../../../context/useAuthContext';
// import { AuthActionSuccess } from '../../../context/reducers/authActions';

const Otp = ({ location, history }) => {
  const [otpvalue, setOtpvalue] = useState('');

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.otp);

  const handleChange = (otp) => setOtpvalue(otp);

  const onVerify = () => {
    dispatch(
      verifyOtpAction({
        data: { code: otpvalue, email: location.state.email, AppId: location.state.email },
        history,
      }),
    );
  };
  return (
    <>
      <div className="flex w-full lg:h-screen">
        <div className="relative h-full justify-center items-center md:w-3/6 lg:w-2/6 hidden md:flex bg-appcolor-900">
          <div
            className={`absolute inset-0 bg-no-repeat bg-cover`}
            style={{
              background: `url(${authOverlay})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              opacity: 0.4,
            }}
          ></div>
          <div className=" w-3/6 h-5/6 relative" style={{ width: '70%' }}>
            <Link to="/" className="w-full flex justify-center items-center">
              <img src={logowhite} className="w-52" />
            </Link>

            <div className="w-5/6 mt-9 mx-auto">
              <p className="text-xl text-white font-bold text-center">Connecting People, Properties and Solutions...</p>
            </div>

            <div className="w-5/6 mt-10 mx-auto">
              <p className="text-base text-slate-400 font-semibold text-center">
                Input The OTP sent to your registerd email/phone number to move to the next step.
              </p>
            </div>
          </div>
        </div>
        <div className=" md:w-3/6 lg:w-4/6 px-3 md:px-0 h-screen w-full sm:w-full flex flex-col relative md:static md:bg-transparent justify-center items-center">
          <div
            className={`absolute  md:hidden inset-0 bg-no-repeat bg-cover`}
            style={{
              background: `url(${authOverlay})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              opacity: 0.4,
            }}
          ></div>

          <div className="lg:w-2/5 md:w-3/5 w-full relative h-full flex flex-col justify-center">
            <div className="flex md:hidden mb-4 justify-center">
              <Link to="/" className=" md:hidden flex justify-center items-center">
                <img src={logo} className="w-40" />
              </Link>
            </div>

            <div
              className="shadow-md rounded p-8 md:p-0 bg-white md:shadow-none md:bg-transparent"
              // style={{ marginTop: '60px', marginBottom: '110px' }}
            >
              <div className="">
                <p className="text-2xl mb-3 mb-1 font-bold text-appcolor-800">Enter Verification Code</p>
                <p className="text-md my-4">
                  We have sent you a verification code <span className="font-semibold">{location?.state?.email}</span>{' '}
                  or <span className="font-semibold">{location?.state?.phone}</span>. Please check
                </p>
              </div>
              <div className="">
                <div style={{ marginBottom: '' }} className="mx-auto my-4">
                  <OtpInput
                    value={otpvalue}
                    onChange={handleChange}
                    numInputs={6}
                    isInputNum={false}
                    separator={<span> </span>}
                    inputStyle={
                      'bg-gray-200 mr-2 border-gray-200 !w-10 h-10 md:!w-12 md:h-12 lg:!w-14 lg:h-14 rounded-md'
                    }
                  />
                </div>
                {/* <div className="my-3">
                <p className="font-bold mb-2 cursor-pointer text-indigo-800 text-sm">Send the code again</p>
                <p className="font-bold mt-2 cursor-pointer text-indigo-800 text-sm">Change phone Number</p>
              </div> */}
                <div>
                  <Button
                    onClick={() => onVerify()}
                    disabled={loading || otpvalue.length !== 6}
                    isLoading={loading}
                    style={{ width: '100%', marginTop: 40, maxWidth: 380 }}
                    className=""
                  >
                    Verify
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <CustomToast />
    </>
  );
};

export default Otp;
