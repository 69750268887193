import api from '../../utils/api';
import { useMutation } from 'react-query';
import { ApiEndpoints } from '../../config/Endpoints';

export async function SignUpUser(payload) {
  const response = await api.post(ApiEndpoints.USER_SIGNUP, payload);
  return response;
}

export async function VerifyOtpUser(payload) {
  const response = await api.post(ApiEndpoints.OTP_VERIFY, payload);
  return response;
}
export async function SignInUser(payload) {
  const response = await api.post(ApiEndpoints.USER_SIGNIN, payload);
  return response;
}
export async function ChangePassword(payload) {
  const response = await api.post(ApiEndpoints.CHANGE_PASSWORD, payload);
  return response;
}
export async function UpdateUser(payload) {
  const response = await api.patch(ApiEndpoints.UPDATE_USER, payload);
  return response;
}
export async function GetUserDetails() {
  const response = await api.get(ApiEndpoints.UPDATE_USER);
  return response;
}

export async function ResetPasswordLink(payload) {
  const response = await api.post(ApiEndpoints.RESET_PASSWORD_LINK, payload);
  return response;
}

export async function ResetPassword(payload) {
  const response = await api.post(ApiEndpoints.RESET_PASSWORD, payload);
  return response;
}

export async function ChangeImage(payload) {
  const response = await api.post(ApiEndpoints.CHANGE_IMAGE, payload);
  return response;
}

// async function ConfirmAccount(payload) {
//   const response = await api.post(ApiEndpoints.CONFIRM_ACCOUNT, payload);
//   return response;
// }

// async function AccountSelect(id) {
//   const response = await api.get(`${ApiEndpoints.ACCOUNT_SELECT}?accountid=${id}`);
//   console.log(response);
//   return response;
// }

// async function UserData(id) {
//   const response = await api.get(`${ApiEndpoints.USER_DATA}/${id}`);

//   return response;
// }

export default {
  useSignUpService: (...args) => useMutation(SignUpUser, ...args),
  useSignInService: (...args) => useMutation(SignInUser, ...args),
  useOtpService: (...args) => useMutation(VerifyOtpUser, ...args),
  useChangePasswordService: (...args) => useMutation(ChangePassword, ...args),
  useUpdateUserService: (...args) => useMutation(UpdateUser, ...args),
};
