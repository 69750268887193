import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssignStaffToCommunity } from '../../../hooks/api/communityService';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const AssignStaffToCommunitySlice = createSlice({
  name: 'communitydetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(assignStaffToCommunityAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(assignStaffToCommunityAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(assignStaffToCommunityAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const assignStaffToCommunityAction = createAsyncThunk('communitydetails', async ({ data }, thunkApi) => {
  // thunkApi.dispatch(changeProgress(60));
  return AssignStaffToCommunity(data)
    .then((res) => {
      // thunkApi.dispatch(changeProgress(100));

      return res;
    })
    .catch((err) => {
      // thunkApi.dispatch(changeProgress(100));
      return thunkApi.rejectWithValue(err?.message);
    });
});

export default AssignStaffToCommunitySlice.reducer;
