import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { getCommunityResident } from '../../../hooks/api/propertyService';

const initialState = {
  loading: false,
  error: null,
  fromDate: null,
  toDate: null,
  response: null,
};

const getCommunityResidentSlice = createSlice({
  name: 'getcommunityresident',
  initialState,
  reducers: {
    changeDate: (state, action) => {
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
    defaultState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCommunityResidentAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCommunityResidentAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(getCommunityResidentAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const getCommunityResidentAction = createAsyncThunk('getCommuntyResident', async (data, thunkApi) => {
  return getCommunityResident(data)
    .then((res) => {
      return res;
    })

    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default getCommunityResidentSlice.reducer;
