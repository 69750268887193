import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DisbursementApproval } from '../../../hooks/api/financeService';
import { disbursementRequestsAction } from './disbursementRequests';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const disbursementApproval = createSlice({
  name: 'disbursementApproval',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(disbursementApprovalAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(disbursementApprovalAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(disbursementApprovalAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const disbursementApprovalAction = createAsyncThunk(
  'disbursementApprovalAction',
  async ({ payload: data, dispatch }, thunkApi) => {
    return DisbursementApproval(data)
      .then((res) => {
        dispatch(disbursementRequestsAction());
        return res;
      })

      .catch((err) => {
        return thunkApi.rejectWithValue(err.message);
      });
  },
);

export default disbursementApproval.reducer;
