import { ApiEndpoints } from '../../config/Endpoints';
import api from '../../utils/api';

export async function getStates() {
  const response = await api.get(`${ApiEndpoints.STATES}`);

  return response;
}
export async function getRegions(payload) {
  const response = await api.get(`${ApiEndpoints.REGIONS}?cid=${payload}`);

  return response;
}
export async function getSubRegions(payload) {
  const response = await api.get(`${ApiEndpoints.SUB_REGIONS}?cid=${payload?.region}&stateid=${payload?.state}`);

  return response;
}
