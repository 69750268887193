import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UpdateUser } from '../../../hooks/api/userService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const updateUserSlice = createSlice({
  name: 'updateUserSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateUserAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(updateUserAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const updateUserAction = createAsyncThunk('updateUserAction', async (data, thunkApi) => {
  thunkApi.dispatch(changeProgress(60));
  return UpdateUser(data)
    .then((res) => {
      thunkApi.dispatch(changeProgress(100));
      return res;
    })
    .catch((err) => {
      thunkApi.dispatch(changeProgress(100));
      return thunkApi.rejectWithValue(err.message);
    });
});

export default updateUserSlice.reducer;
