import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetWorkOrderActivities } from '../../../hooks/api/maintenanceService';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const workorderActivity = createSlice({
  name: 'workorderActivity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(workorderActivityAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(workorderActivityAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(workorderActivityAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const workorderActivityAction = createAsyncThunk('workorderActivityAction', (data, thunkApi) => {
  return GetWorkOrderActivities(data)
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default workorderActivity.reducer;
