import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SetDisbursementSetup } from '../../../hooks/api/financeService';
import { getDisbursementAction } from './getDisbursement';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const setDisbursement = createSlice({
  name: 'setDisbursement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setDisbursementAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setDisbursementAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(setDisbursementAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const setDisbursementAction = createAsyncThunk(
  'setDisbursementAction',
  async ({ payload: data, dispatch, setAddStaff }, thunkApi) => {
    return SetDisbursementSetup(data)
      .then((res) => {
        dispatch && dispatch(getDisbursementAction());
        setAddStaff && setAddStaff(false);
        return res;
      })
      .catch((err) => {
        return thunkApi.rejectWithValue(err.message);
      });
  },
);

export default setDisbursement.reducer;
