import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ViewWorkOrderStaff } from '../../../hooks/api/maintenanceService';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

export const viewWorkOrderStaffSlice = createSlice({
  name: 'viewWorkOrderSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(viewWorkOrderStaffAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(viewWorkOrderStaffAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(viewWorkOrderStaffAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const viewWorkOrderStaffAction = createAsyncThunk('viewWorkOrderAction', async (data, thunkApi) => {
  return ViewWorkOrderStaff(data)
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default viewWorkOrderStaffSlice.reducer;
