import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { UpdateWorkorderStaff, ViewWorkOrderStaff } from '../../../hooks/api/maintenanceService';
import customToastComponent from '../../../utils/customToast';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const updateWorkorderStaffSlice = createSlice({
  name: 'updateWorkorderStaffSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateWorkorderStaffAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateWorkorderStaffAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(updateWorkorderStaffAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const updateWorkorderStaffAction = createAsyncThunk('updateWorkorderStaffAction', async (data, thunkApi) => {
  console.log('hi', data);
  return UpdateWorkorderStaff(data)
    .then((res) => {
      customToastComponent(res?.data?.message || 'updated successfull');
      return res;
    })
    .catch((err) => {
      customToastComponent(err.message, true);
      thunkApi.rejectWithValue(err.message);
    });
});

export default updateWorkorderStaffSlice.reducer;
