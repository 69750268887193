import { authAction } from './actionTypes';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const { AuthSuccess, AuthFail, AuthAddAccount } = authAction;

const PartnerAuthReducer = (state, action) => {
  switch (action.type) {
    case AuthFail:
      return {
        ...state,
        token: null,
        partner: null,
        permission: null,
        partner: null,
      };
    case AuthSuccess:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.currentUser,
        permission: action.payload.permission,
        partner: action.payload.partner,
      };

    case AuthAddAccount:
      return {
        ...state,
        partner: action.payload.partner,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export { PartnerAuthReducer };
