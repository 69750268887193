import { authAction } from './actionTypes';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const { AuthSuccess, AuthFail, AuthAddAccount } = authAction;

const AuthReducer = (state, action) => {
  switch (action.type) {
    case AuthFail:
      return {
        ...state,
        token: null,
      };
    case AuthSuccess:
      return {
        ...state,
        token: action.payload.token,
      };

    case AuthAddAccount:
      return {
        ...state,
        accounts: action.payload.accounts,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export { AuthReducer };
