import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PartnerDetail } from '../../../hooks/api/partnerService';

const initialState = {
  loading: false,
  response: [],
  error: '',
};

const partnerDetailSlice = createSlice({
  name: 'partnerDetailSlice',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(partnerDetailAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(partnerDetailAction.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
    });
    builder.addCase(partnerDetailAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const partnerDetailAction = createAsyncThunk('partnerDetailAction', async (thunkApi) => {
  return PartnerDetail()
    .then((res) => res)
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default partnerDetailSlice.reducer;
