import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RemoveRestriction } from '../../../hooks/api/residenceService';
import customToastComponent from '../../../utils/customToast';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const removeRestrictionSlice = createSlice({
  name: 'removeRestriction',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(removeRestrictionAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeRestrictionAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(removeRestrictionAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const removeRestrictionAction = createAsyncThunk('removeRestrictionAction', async (data, thunkApi) => {
  return RemoveRestriction(data)
    .then((res) => {
      customToastComponent(res?.message);
      return res;
    })
    .catch((err) => {
      customToastComponent(err?.message, true);
      return thunkApi.rejectWithValue(err.message);
    });
});

export default removeRestrictionSlice.reducer;
