import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { BsArrowRight } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { logo, house, authOverlay } from '../../../Entryfile/imagepath';
import Timeline from './Timeline';
import Video from './Video';

const UnverifiedAccount = ({}) => {
  const history = useHistory();
  const timelineContent = [
    {
      title: 'Manage all your properties',
      body: 'From the comfort of your home you can now manage your properties and residents',
    },
    {
      title: 'Single Collection Point',
      body: 'Lorem ipsum dolor sit amet, from the comfort of your home you can now manage your properties and residents',
    },
    {
      title: 'Manage Facility Processes Seamlessly',
      body: 'From the comfort of your home you can now manage your properties and residents',
    },
    {
      title: 'Make Money on Dweller',
      body: 'From the comfort of your home you can now manage your properties and residents',
    },
  ];

  return (
    <div className="flex md:flex-row flex-col w-full xl:h-screen 3xl:overflow-y-hidden">
      {/* starts */}
      <div className="max-h-screen overflow-y-auto py-10 relative justify-center w-0 hidden md:grid md:w-7/12 bg-appcolor-900">
        <div
          className={`absolute inset-0 bg-no-repeat bg-cover bg-center`}
          style={{
            backgroundImage: `url(${house})`,
            opacity: 0.1,
          }}
        ></div>
        <div className="flex flex-col text-white w-full max-w-[500px]" style={{ width: '90%' }}>
          <p className="mb-2 opacity-80 text-2xl font-semibold flex gap-2">Recommended watch</p>
          <Video />
          <div className="timeline mt-9 text-white">
            {timelineContent.map((item, idx) => (
              <Timeline totalCount={timelineContent.length} item={item} idx={idx} key={idx} />
            ))}
          </div>
        </div>
      </div>
      {/* ends */}
      <div className="w-full px-3 md:px-0 md:w-5/12 h-screen flex flex-col relative justify-center items-center">
        <div className="flex flex-1 flex-col justify-center">
          <div
            className={`absolute  md:hidden inset-0 bg-no-repeat bg-cover`}
            style={{
              background: `url(${authOverlay})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              opacity: 0.4,
            }}
          ></div>
          <div className="w-full flex justify-center items-center">
            <img src={logo} className="w-52" alt="logo" />
          </div>
          <div className="mt-10 max-w-md text-center px-10">
            <p className="text-3xl font-semibold text-center">
              This Account is Awaiting <br /> Approval
            </p>
            <p className="mt-10 text-base">
              You'll receive a call from our agent in an hour time regarding the next step. Meanwhile you can :
            </p>
            <button className="px-6 py-2 bg-appcolor-600 text-white rounded mt-2" onClick={() => history.push('/')}>
              Try Login Again
            </button>
          </div>
        </div>
        <div className="max-w-md pb-4 text-center">
          <p className="font-semibold">
            In case this page persists longer than neccessary, contact our support team at suppport@dweller.africa
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnverifiedAccount;
