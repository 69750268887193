import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetAllCollection } from '../../../hooks/api/collectionService';
import { changeProgress } from '../toploader/toploaderSlice';

const initialState = {
  loading: false,
  error: null,
  response: null,
};

const getAllCollectionSlice = createSlice({
  name: 'getAllColletion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCollectionAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCollectionAction.fulfilled, (state, action) => {
      state.response = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllCollectionAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const getAllCollectionAction = createAsyncThunk('getAllColletion', async (data, thunkApi) => {
  return GetAllCollection(data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default getAllCollectionSlice.reducer;
