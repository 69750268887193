export const Video = () => {
  return (
    <div className="mt-2 rounded h-[300px] place-content-start">
      <iframe
        width="500px"
        height="300px"
        // src="https://www.youtube.com/watch?v=ENvd-QwMi3Q"
        src="https://www.youtube.com/embed/ENvd-QwMi3Q"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default Video;
