import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetSTaffSummary } from '../../../hooks/api/partnerService';

const initialState = {
  loading: false,
  error: '',
  data: [],
};

const staffSummarySlice = createSlice({
  name: 'staffSummarySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(StaffSummaryAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(StaffSummaryAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(StaffSummaryAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const StaffSummaryAction = createAsyncThunk('staffSummaryAction', (data, apiThunk) => {
  return GetSTaffSummary()
    .then((res) => res)
    .catch((err) => apiThunk.rejectWithValue(err.message));
});

export default staffSummarySlice.reducer;
