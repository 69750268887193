import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { getResidentTable } from '../../../hooks/api/residenceService';

const initialState = {
  tableLoading: false,
  error: null,
  data: null,
};

const viewResidentDetailTableSlice = createSlice({
  name: 'getResidentTable',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getResidentTableAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getResidentTableAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.tableLoading = false;
    });
    builder.addCase(getResidentTableAction.rejected, (state, action) => {
      state.error = action.payload;
      state.tableLoading = false;
    });
  },
});

export const getResidentTableAction = createAsyncThunk('getResidentTable', async (payload, thunkApi) => {
  return getResidentTable(payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return thunkApi.rejectWithValue(err.message);
    });
});

export default viewResidentDetailTableSlice.reducer;
