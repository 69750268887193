import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetAllServiceRequestTypes } from '../../../hooks/api/maintenanceService';

const initialState = {
  data: {},
  loading: false,
  error: '',
};

const allServiceRequestSlice = createSlice({
  name: 'allServiceRequestSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allServiceRequestAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(allServiceRequestAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(allServiceRequestAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const allServiceRequestAction = createAsyncThunk('allServiceRequestAction', async (data, thunkApi) => {
  return GetAllServiceRequestTypes(data)
    .then((res) => {
      return res;
    })
    .catch((err) => thunkApi.rejectWithValue(err.message));
});

export default allServiceRequestSlice.reducer;
